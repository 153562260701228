














































import SupplierPaymentCategoryApi from "@/modules/supplier/api/supplier-payment-category.api";
import SupplierPaymentCategoryForm from "../components/supplier/SupplierPaymentCategoryForm.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { SupplierPaymentCategory } from "@/modules/supplier/types/supplier-payment-category.type";
import { DataTableHeader } from "vuetify";
import SettingsHeader from "../components/SettingsHeader.vue";

@Component({
  components: {
    ErrorLogo,
    SupplierPaymentCategoryForm,
    SettingsHeader
  }
})
export default class ManageSupplierPaymentCategoriesPage extends Vue {
  @Getter("auth/isRtl") isRtl: boolean;
  public loadErrors: any = false;
  public showSupplierPaymentCategoryForm = false;
  public editSupplierPaymentCategory: SupplierPaymentCategory = null;
  public loading = false;
  public supplierPaymentCategories: SupplierPaymentCategory[] = [];

  public openSupplierPaymentCategoryFormDialog(supplierPaymentCategory: SupplierPaymentCategory) {
    this.editSupplierPaymentCategory = supplierPaymentCategory;
    this.showSupplierPaymentCategoryForm = true;
  }

  public closeSupplierPaymentCategoryForm() {
    this.editSupplierPaymentCategory = null;
    this.showSupplierPaymentCategoryForm = false;
  }

  public async reload() {
    await this.loadSupplierPaymentCategories();
  }

  public async loadSupplierPaymentCategories() {
    this.loadErrors = null;
    this.loading = true;
    this.supplierPaymentCategories = [];

    try {
      this.supplierPaymentCategories = await SupplierPaymentCategoryApi.getAll();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.loadErrors = err.response.data.errors;
      } else {
        this.loadErrors = this.$t("temporary_error");
      }
    }
  }

  public async mounted() {
    await this.loadSupplierPaymentCategories();
  }

  public pinkasName(pinkasId: number) {
    return this.$store.getters["auth/pinkasList"].find(({ id }) => (id === pinkasId)).business_name;
  }

  get headers() {
    const headers: DataTableHeader[] = [
      { width: "30%", text: this.$t("headers.name").toString(), value: "name" },
    ];

    if (this.$store.getters["auth/pinkasList"].length > 1) {
      headers.push(
        { width: "30%", text: this.$t("headers.pinkas").toString(), value: "pinkas", sortable: false }
      );
    }
    headers.push(
      { width: "30%", text: this.$t("headers.tax_recognized_rate").toString(), value: "tax_recognized_rate" },
      { width: "20%", text: this.$t("headers.actions").toString(), value: "actions", sortable: false }
    );

    return headers;
  }
}
