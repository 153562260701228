export enum ReportApiRoutes {
  exportOpenFormatReport = "/report/open-format-export",
  exportHashavshevetReport = "/report/hashavshevet-export",
  exportAllInvoicesReport = "/report/all-invoices",
  documentNumberReport = "/report/document-number",
  allReceiptsReport = "/report/all-receipts",
  allIncomesReport = "/report/all-incomes",
  yearlyIncomesReport = "/report/yearly-incomes",
  yearlyAppointmentsReport = "/report/yearly-appointments",
  patientsDebtsReport = "/report/patients-debts",
  patientsDebtsExport = "/report/patients-debts-export",
  doctorCalendarReport = "/report/doctor-calendar",
  clinicKupaReport = "/report/clinic-kupa",
  clinicKupaDeposits = "/report/clinic-kupa-deposits",
  markDepositIncome = "/report/mark-deposit-income",
  exportKupaDepositsReport = "/report/kupa-deposits-export",
}
