














import { Component, Prop } from "vue-property-decorator";
import { PatientFile } from "@/modules/patient-file/types/patient-file.type";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";

@Component({
})
export default class DisplayPatientFiles extends mixins(SmileMixin) {
  @Prop({ required: true }) public file: PatientFile;
  @Getter("auth/isRtl") isRtl: boolean;

}
