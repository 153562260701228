









































































































































































































































import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { Patient } from "@/modules/patient/types/patient.type";
import { PatientFolder } from "../../types/patient-folder.type";
import FolderFormDialog from "./FolderFormDialog.vue";
import PatientFolderApi from "../../api/patient-folder.api";
import PatientFileListItem from "@/modules/patient-file/components/patient-file/PatientFileListItem.vue";
import { PatientFile } from "../../types/patient-file.type";
import ShowPatientFileDialog from "../patient-file/ShowPatientFileDialog.vue";
import PatientFileApi from "@/modules/patient-file/api/patient-file.api";
import ExplorerFileActionButton from "./ExplorerFileActionButton.vue";
import ExplorerFolderActionButton from "./ExplorerFolderActionButton.vue";
import RenamePatientFileDialog from "../patient-file/RenamePatientFileDialog.vue";
import MovePatientFileDialog from "../patient-file/MovePatientFileDialog.vue";
import MovePatientFilesDialog from "../patient-file/MovePatientFilesDialog.vue";
import MovePatientFolderDialog from "../patient-folder/MovePatientFolderDialog.vue";
import { Getter } from "vuex-class";

type VIEW_LAYOUT_LIST = "list";
type VIEW_LAYOUT_GRID = "grid";
type SORT_BY_CREATED_AT = "created_at";
type SORT_BY_NAME = "name";
const LOCALSTORAGE_LAYOUT_KEY = "patient-file-explorer-layout";
const LOCALSTORAGE_SORT_KEY = "patient-file-explorer-sort";

@Component({
  components: {
    ShowPatientFileDialog,
    PatientFileListItem,
    FolderFormDialog,
    ExplorerFolderActionButton,
    ExplorerFileActionButton,
    RenamePatientFileDialog,
    MovePatientFileDialog,
    MovePatientFilesDialog,
    MovePatientFolderDialog,
  },
})
export default class PatientFileExplorerDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: false, default: false }) public selectFiles: boolean;
  @Prop({ required: false }) public selectedFileIds: number[];
  @Getter("auth/isRtl") isRtl: boolean;

  $refs!: {
    uploadInput: HTMLFormElement;
  }
  public isDragOver = false;
  public viewLayout: VIEW_LAYOUT_LIST | VIEW_LAYOUT_GRID = "grid";
  public sortBy: SORT_BY_CREATED_AT | SORT_BY_NAME = "created_at";
  public currentFolderId: number = null;
  public editingFolder: PatientFolder = null;
  public editingFile: PatientFile = null;
  public isDisplayFolderFormDialog = false;
  public isDisplayRenameFileDialog = false;
  public isDisplayMoveFileDialog = false;
  public isDisplayMoveFolderDialog = false;
  public isDisplayMoveFilesDialog = false;
  public isCreateFolderLoading = false;
  public showPatientFile: PatientFile = null;
  public uploadLoading = false;
  public isSelectionEnabled = false;
  public selectedFilesIds: number[] = [];
  public selectedFoldersIds: number[] = [];
  public isSelectedDeleteLoading = false;
  public isSelectedMoveLoading = false;
  public navigateImages: PatientFile[] = [];

  get sortOptions() {
    return [
      {
        text: this.$t("sort_by_created_at"),
        value: "created_at"
      },
      {
        text: this.$t("sort_by_name"),
        value: "name"
      }
    ];
  }

  get currentFolder() {
    return this.patient.folders.find(({ id }) => (id === this.currentFolderId));
  }

  public onFileSelected(file: PatientFile) {
    this.showPatientFile = file;
  }

  public closeDialog() {
    this.$emit("close");
  }
  public handleLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isDragOver = false;
  }
  public handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length) {
      this.uploadFiles(files);
    }
    this.isDragOver = false;
  }

  public addDragDropListeners() {
    const dropArea = document.getElementById("drop-area");
    dropArea.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.isDragOver = true;
    });
    dropArea.addEventListener("dragleave", this.handleLeave);
    dropArea.addEventListener("drop", this.handleDrop);
  }

  public removeDragDropListeners() {
    const dropArea = document.getElementById("drop-area");
    dropArea.removeEventListener("dragover", (event) => {
      event.preventDefault();
      this.isDragOver = true;
    });
    dropArea.removeEventListener("dragleave", this.handleLeave);
    dropArea.removeEventListener("drop", this.handleDrop);
  }

  public mounted() {
    this.addDragDropListeners();

    if (localStorage.getItem(LOCALSTORAGE_LAYOUT_KEY) === "list") {
      this.viewLayout = "list";
    }
    if (localStorage.getItem(LOCALSTORAGE_SORT_KEY) === "name") {
      this.sortBy = "name";
    }

    if (this.selectFiles) {
      this.isSelectionEnabled = true;
      this.selectedFilesIds = [...this.selectedFileIds];
    }
  }

  beforeDestroy() {
    this.removeDragDropListeners();
  }

  public onViewLayoutChanged() {
    localStorage.setItem(LOCALSTORAGE_LAYOUT_KEY, this.viewLayout);
  }

  public onSortByChanged() {
    localStorage.setItem(LOCALSTORAGE_SORT_KEY, this.sortBy);
  }

  public onMoveFileClicked(file: PatientFile) {
    this.editingFile = file;
    this.isDisplayMoveFileDialog = true;
  }

  public onRenameFileClicked(file: PatientFile) {
    this.editingFile = file;
    this.isDisplayRenameFileDialog = true;
  }

  public onMoveFilesClicked() {
    this.isDisplayMoveFilesDialog = true;
  }

  public onSelectedCreateLetterClicked() {
    this.$emit("createLetter", this.selectedFilesIds);
  }

  public async onSelectedDeleteClicked() {
    const message = this.selectedFilesIds.length && this.selectedFoldersIds.length ? "delete_files_folders_confirm_message" : (
      this.selectedFilesIds.length ? "delete_files_confirm_message" : "delete_folders_confirm_message"
    );
    const res = await this.$confirm(this.$t(message, { count: this.selectedFilesIds.length, foldersCount: this.selectedFoldersIds.length }).toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_file_ok_btn").toString(),
      buttonFalseText: this.$t("delete_file_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    this.isSelectedDeleteLoading = true;
    const promises = [];
    this.selectedFilesIds.forEach(fileId => {
      promises.push(PatientFileApi.delete(fileId));
    });

    this.selectedFilesIds = [];
    this.isSelectionEnabled = false;
    Promise.allSettled(promises).then(() => {
      this.isSelectedDeleteLoading = false;
      this.$toastr.s(this.$t("deleted_files_successfully"));
      this.$emit("reloadPatient");
    });
  }

  public async onPatientFilesMoved(folderId: number) {
    this.isDisplayMoveFilesDialog = false;
    this.isSelectedMoveLoading = true;
    const promises = [];
    this.selectedFilesIds.forEach(fileId => {
      const file = this.patient.files.find(({ id }) => (fileId === id));
      promises.push(PatientFileApi.update(fileId, file.name, folderId));
    });

    this.selectedFilesIds = [];
    this.isSelectionEnabled = false;
    Promise.allSettled(promises).then(() => {
      this.isSelectedMoveLoading = false;
      this.$toastr.s(this.$t("moved_files_successfully"));
      this.$emit("reloadPatient");
    });
  }

  get isMoveDisabled() {
    return this.selectedFoldersIds.length > 0;
  }

  get isDeleteDisabled() {
    return !!this.patient.folders.find(({ id, is_protected }) => (is_protected && this.selectedFoldersIds.includes(id)));
  }

  public async onDeleteFileClicked(file: PatientFile) {
    const res = await this.$confirm(this.$t("delete_file_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_file_ok_btn").toString(),
      buttonFalseText: this.$t("delete_file_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    try {
      await PatientFileApi.delete(file.id);
      this.$toastr.s(this.$t("deleted_file_successfully"));
      this.$emit("reloadPatient");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public isFolderSelected(folderId: number) {
    return this.selectedFoldersIds.includes(folderId);
  }

  public isFileSelected(fileId: number) {
    return this.selectedFilesIds.includes(fileId);
  }

  public onSelectFolderClicked(folder: PatientFolder) {
    if (this.selectedFoldersIds.includes(folder.id)) {
      const index = this.selectedFoldersIds.indexOf(folder.id);
      this.selectedFoldersIds.splice(index, 1);
      if (this.selectedFilesIds.length + this.selectedFoldersIds.length === 0 && !this.selectFiles) {
        this.isSelectionEnabled = false;
      }
      this.selectFolderFiles(folder, "deselect");
    } else {
      this.selectedFoldersIds.push(folder.id);
      this.isSelectionEnabled = true;
      this.selectFolderFiles(folder, "select");
    }
  }

  private selectFolderFiles(folder: PatientFolder, selectOrDeselect: "select" | "deselect") {
    this.patient.files.forEach(file => {
      if (file.folder_id !== folder.id) {
        return;
      }

      if ((selectOrDeselect === "select" && this.selectedFilesIds.includes(file.id)) || (selectOrDeselect === "deselect" && !this.selectedFilesIds.includes(file.id))) {
        return;
      }

      this.onSelectFileClicked(file);
    });

    this.patient.folders.forEach(folderI => {
      if (folderI.parent_folder_id === folder.id) {
        this.selectFolderFiles(folderI, selectOrDeselect);

        if (this.selectedFoldersIds.includes(folderI.id)) {
          const index = this.selectedFoldersIds.indexOf(folderI.id);
          this.selectedFoldersIds.splice(index, 1);
          if (this.selectedFilesIds.length + this.selectedFoldersIds.length === 0 && !this.selectFiles) {
            this.isSelectionEnabled = false;
          }
        } else {
          this.selectedFoldersIds.push(folderI.id);
          this.isSelectionEnabled = true;
        }
      }
    });
  }

  public onSelectFileClicked(file: PatientFile) {
    if (this.selectedFilesIds.includes(file.id)) {
      const index = this.selectedFilesIds.indexOf(file.id);
      this.selectedFilesIds.splice(index, 1);
      if (this.selectedFilesIds.length + this.selectedFoldersIds.length === 0 && !this.selectFiles) {
        this.isSelectionEnabled = false;
      }
    } else {
      this.selectedFilesIds.push(file.id);
      this.isSelectionEnabled = true;
    }
  }

  public onDownloadFileClicked(file: PatientFile) {
    this.downloadFile(file.download_url, file.name);
  }

  public onCreateFolderClicked() {
    this.editingFolder = null;
    this.isDisplayFolderFormDialog = true;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public async onUpdateFolder(folderId: number, name: string, parentFolderId: number) {
    try {
      await PatientFolderApi.update(folderId, name, parentFolderId);
      this.$toastr.s(this.$t("folder_updated_toastr"));
      this.$emit("reloadPatient");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onCreateFolder(name: string) {
    try {
      this.isCreateFolderLoading = true;
      await PatientFolderApi.create(this.patient.id, name, this.currentFolderId);
      this.$toastr.s(this.$t("folder_created_toastr"));
      this.$emit("reloadPatient");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    } finally {
      this.isCreateFolderLoading = false;
    }
  }

  public getBreadcrumbsItems(folderId: number) {
    const folder = this.patient.folders.find(({ id }) => (id === folderId));

    if (folder) {

      return [
        ...this.getBreadcrumbsItems(folder.parent_folder_id),
        {
          text: folder.name,
          folderId: folder.id,
        }
      ];
    }

    return [
      {
        text: this.$t("title") + " " + this.patient.first_name + " " + this.patient.last_name,
        folderId: null,
      }
    ];
  }

  get breadcrumbs() {
    return this.getBreadcrumbsItems(this.currentFolderId);
  }

  public fileNameUpdated(id: number, newName: string) {
    const file = this.patient.files.find(file => (file.id === id));
    if (file) {
      file.name = newName;
    }
  }

  public fileDeleted() {
    this.$emit("reloadPatient");
  }

  public openFile(file: PatientFile) {
    this.showPatientFile = file;
    this.navigateImages = this.displayFiles.filter(({ type }) => (type === "photo"));
  }

  public closeShowPatientFileDialog() {
    this.showPatientFile = null;
    this.navigateImages = [];
  }

  public uploadFileClicked() {
    this.$refs.uploadInput.click();
  }

  public async uploadFiles(fileList) {
    if (!fileList.length) return;

    this.uploadLoading = true;
    const promises = [];
    for (let i = 0; i < fileList.length; i++) {
      const formData = new FormData();
      formData.append("file", fileList[i], fileList[i].name);
      formData.append("patient_id", this.patient.id.toString());
      formData.append("folder_id", this.currentFolderId ? this.currentFolderId.toString() : "");

      const uploadPromise = PatientFileApi.create(formData)
        .then(() => {
          this.$toastr.s(this.$t("uploaded_successfully"));
        });
      promises.push(uploadPromise);
    }

    Promise.allSettled(promises).then(() => {
      this.uploadLoading = false;
      this.$emit("reloadPatient");
      if (this.$refs && this.$refs.uploadInput && this.$refs.uploadInput.value) {
        this.$refs.uploadInput.value = "";
      }
    });
  }

  get displayFolders() {
    return this.patient.folders
      .filter(({ parent_folder_id }) => (parent_folder_id === this.currentFolderId))
      .sort((a, b) => {
        if (this.sortBy === "name") {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        } else {
          if (a.created_at > b.created_at) return -1;
          if (a.created_at < b.created_at) return 1;
          return 0;
        }
      });
  }

  get displayFiles() {
    return this.patient.files
      .filter(({ folder_id }) => (folder_id === this.currentFolderId))
      .sort((a, b) => {
        if (this.sortBy === "name") {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        } else {
          if (a.created_at > b.created_at) return -1;
          if (a.created_at < b.created_at) return 1;
          return 0;
        }
      });
  }

  public async onPatientFileMoved(name: string, fileId: number, folderId: number) {
    try {
      await PatientFileApi.update(fileId, name, folderId);
      this.$emit("reloadPatient");
      this.$toastr.s(this.$t("file_updated_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onPatientFolderMoved(name: string, folderId: number, parentFolderId: number) {
    try {
      await PatientFolderApi.update(folderId, name, parentFolderId);
      this.$emit("reloadPatient");
      this.$toastr.s(this.$t("folder_updated_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onPatientFileRenamed(newName: string, fileId: number, folderId: number) {
    try {
      await PatientFileApi.update(fileId, newName, folderId);
      this.fileNameUpdated(fileId, newName);
      this.$toastr.s(this.$t("name_updated_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public onRenameFolderClicked(folder: PatientFolder) {
    this.editingFolder = folder;
    this.isDisplayFolderFormDialog = true;
  }

  public onMoveFolderClicked(folder: PatientFolder) {
    this.editingFolder = folder;
    this.isDisplayMoveFolderDialog = true;
  }

  public async onDeleteFolderClicked(folder: PatientFolder) {
    const res = await this.$confirm(this.$t("delete_folder_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_folder_ok_btn").toString(),
      buttonFalseText: this.$t("delete_folder_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    try {
      await PatientFolderApi.delete(folder.id);
      this.$toastr.s(this.$t("deleted_folder_successfully"));
      this.$emit("reloadPatient");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  private searchDeepFiles(folder: PatientFolder) {
    if (!folder && this.patient.files.length > 0) {
      return true;
    }

    if (this.patient.files.find(({ folder_id }) => (folder_id === folder.id))) {
      return true;
    }

    if (this.patient.folders.filter(({ parent_folder_id }) => (parent_folder_id === folder.id)).find(this.searchDeepFiles)) {
      return true;
    }

    return false;
  }

  public onSelectAllClicked(folder: PatientFolder) {
    this.patient.files.forEach(({ id, folder_id }) => {
      if ((!folder && !folder_id || (folder && folder_id !== folder.id)) && !this.selectedFilesIds.includes(id)) {
        this.selectedFilesIds.push(id);
      }
    });
    this.patient.folders.forEach((folderI) => {
      if ((!folder && !folderI.parent_folder_id || (folder && folderI.parent_folder_id !== folder.id)) && !this.selectedFoldersIds.includes(folderI.id)) {
        this.selectedFoldersIds.push(folderI.id);
        this.selectFolderFiles(folderI, "select");
      }
    });
  }

  public async onDownloadFolderClicked(folder: PatientFolder) {

    if (!this.searchDeepFiles(folder)) {
      this.$toastr.i(this.$t("unable_download_empty_folder"));
      return;
    }

    try {
      window.open(PatientFolderApi.getExportUrl(this.patient.id, folder ? folder.id : null), "_blank");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

}
