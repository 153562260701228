









































































import { Component, Prop } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { CalendarViewTypes, VuetifyCalendarLocale } from "../../types/calendar.type";
import { SmileCalendarEvent } from "../../types/calendar.type";
import moment from "moment";
import CalendarEvent from "./calendar/CalendarEvent.vue";
import { holidaysSmileCalendarEvent } from "./israel-holidays";
import { User } from "@/modules/users/types/user.type";
import { Getter } from "vuex-class";
import { Shift } from "@/modules/shift/types/shift.type";
import { RichAppointment } from "../../types/appointment.type";

@Component({
  components: {
    CalendarEvent,
  },
})
export default class SmileCalendar extends mixins(SmileMixin) {
  @Prop({ required: true }) focusDate: string;
  @Prop({ required: true }) calendarView: CalendarViewTypes;
  @Prop({ required: true }) appointments: RichAppointment[];
  @Prop({ required: true }) shifts: Shift[];
  @Prop({ required: true }) displayUserIds: number[];
  @Prop({ required: true }) isDisplayCanceled: boolean;
  @Prop({ required: true }) isLoading: boolean;
  @Getter("auth/calendarStartHour") calendarStartHour: number;
  @Getter("auth/calendarEndHour") calendarEndHour: number;
  @Getter("auth/calendarIntervalMinutes") calendarIntervalMinutes: number;
  @Getter("auth/isCalendarDayCategory") isCalendarDayCategory: boolean;

  $refs!: {
    smilevcalendar: any;
  }
  public isMounted = false;
  public nowDate = new Date();
  public refreshInterval: number = null

  public usersBirthDayEvents: SmileCalendarEvent[] = [];
  // TODO config interval minutes

  get calendarType() {
    return this.calendarView === "day" && this.isCalendarDayCategory ? "category" : this.calendarView;
  }

  get calendarStartTime() {
    if (this.calendarStartHour < 10) {
      return `0${this.calendarStartHour}:00`;
    }
    return `${this.calendarStartHour}:00`;
  }

  get enableCurrentNow() {
    return this.$store.getters["auth/clinicName"] !== "מרפאת רומנו";
  }

  public dayHeaderClicked(locale: VuetifyCalendarLocale) {
    if (this.calendarView === "day") {
      this.$emit("calendarViewChanged", "week");
    } else {
      this.$emit("calendarViewChanged", "day");
      this.$emit("focusDateChanged", locale.date);
    }
  }

  public getAppointmentsCount(date: string, statusId?: number) {
    return this.appointments
      .filter((appointment) => (appointment
        && appointment.patient_id
        && date === moment(appointment.start_date).format("YYYY-MM-DD")
        && (!statusId || appointment.status_id === statusId))
      )
      .length;
  }

  public intervalFormatter(locale: VuetifyCalendarLocale) {
    return locale.time;
  }

  public dayFormatter(locale: VuetifyCalendarLocale) {
    return `${locale.day}/${locale.month}`;
  }

  public getEventColor(event: SmileCalendarEvent) {
    if (event.color) {
      return event.color;
    }
    if (!event.appointment) {
      return "#008f9e";
    }
    if (!event.appointment.patient_id) {
      return "#ddd";
    }

    if (this.$store.getters["auth/calendarViewColorType"] === "appointment-type") {
      return this.getAppointmentTypeColor(event.appointment.type_id);
    }

    if (this.$store.getters["auth/calendarViewColorType"] === "patient-status") {
      if (event.appointment.patient_id && event.appointment.patient.status_id) {
        return this.getPatientStatusColor(event.appointment.patient.status_id);
      }

      if (event.appointment.isFirstAppointment) {
        return "#F9C35F";
      } else {
        return "#FBEECD";
      }
    }

    return this.getEventColorByAppointment(event);
  }

  public getEventColorByAppointment(event: SmileCalendarEvent) {
    if (
      event.appointment.status_id === 4 &&
      !event.appointment.isTreatmentDocumented
    ) {
      return "#95b6ff";
    }

    return this.getAppointmentStatusColor(event.appointment.status_id);
  }

  public async reloadAppointments({ start, end }) {
    this.$emit("calendarDatesChanged", start, end);
  }

  public onCalendarEventClicked(event: SmileCalendarEvent) {
    this.$emit("calendarEventClicked", event);
  }

  public showFirstBullet(date: string, category: string) {
    return (this.isToday(date) && !category) || (this.isToday(date) && this.isFirstCategory(category));
  }

  public isFirstCategory(category: string) {
    if (!category) {
      return false;
    }
    return this.calendarCategories[0] === category;
  }

  public isToday(date: string) {
    return date === moment().format("YYYY-MM-DD");
  }

  get calendarRef() {
    return this.isMounted ? this.$refs.smilevcalendar : null;
  }

  get nowY() {
    if (!this.nowDate) {
      // Trick to force refresh nowY computed
      return null;
    }

    if (!this.calendarRef) {
      return "-10px";
    }

    const y = this.calendarRef.timeToY({
      hour: moment().hour(),
      minute: moment().minute(),
    });

    if (y === 0) {
      return "-10px";
    }

    return y + "px";
  }

  public onTimeClicked(locale: VuetifyCalendarLocale) {
    if (this.calendarType === "category" && !locale.category) {
      // Avoid double click for @click:time
      return;
    }

    const roundedMinute = Math.floor((locale.minute / (this.calendarIntervalMinutes))) * this.calendarIntervalMinutes;
    const roundedDate = `${locale.date} ${locale.hour < 10 ? `0${locale.hour}` : locale.hour}:${roundedMinute < 10 ? `0${roundedMinute}` : roundedMinute}:00`;

    const userId = locale.category ? this.userNameToId(locale.category) : null;
    this.$emit("timeClicked", roundedDate, userId);
  }

  public mounted() {
    this.calculateUsersBirthDayEvents();
    this.isMounted = true;


    this.refreshInterval = setInterval(() => {
      // Trick to force refresh nowY computed
      this.nowDate = new Date();
    }, 60 * 1000);
  }

  destroyed() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public calculateUsersBirthDayEvents() {
    const focusYear = parseInt(moment(this.focusDate).format("YYYY"));
    const usersWithBirthDate = (this.$store.getters["auth/users"] as User[])
      .filter(({ deleted_at }) => (!deleted_at))
      .filter(({ birth_date }) => (birth_date));

    const events: SmileCalendarEvent[] = [];

    usersWithBirthDate.forEach(({ birth_date, name }) => {
      events.push({
        appointment: null,
        name: name + " 🎈",
        color: "indigo lighten-3",
        start: moment(birth_date).year(focusYear).format("YYYY-MM-DD"),
        end: moment(birth_date).year(focusYear).format("YYYY-MM-DD"),
        timed: true,
        allDay: true,
      }, {
        appointment: null,
        name: name + " 🎈",
        color: "indigo lighten-3",
        start: moment(birth_date).year(focusYear - 1).format("YYYY-MM-DD"),
        end: moment(birth_date).year(focusYear - 1).format("YYYY-MM-DD"),
        timed: true,
        allDay: true,
      }, {
        appointment: null,
        name: name + " 🎈",
        color: "indigo lighten-3",
        start: moment(birth_date).year(focusYear + 1).format("YYYY-MM-DD"),
        end: moment(birth_date).year(focusYear + 1).format("YYYY-MM-DD"),
        timed: true,
        allDay: true,
      });
    });

    this.usersBirthDayEvents = events;
  }

  public isShift(date: string, time: string, category: string) {
    const currentUserName = category ? category : (this.displayUserIds.length === 1 ? this.userName(this.displayUserIds[0]) : null);
    const datetime = `${date} ${time}:00`;
    return !!this.shifts.find(shift => {
      if (this.userName(shift.user_id) !== currentUserName) {
        return false;
      }

      return shift.start_date <= datetime && shift.end_date > datetime;
    });
  }

  get calendarCategories() {
    return this.displayUserIds.map(userId => (this.userName(userId)));
  }

  get hideCancelStatuses() {
    const hideStatuses = [8]; // Canceled

    if (this.$store.getters["auth/calendarHideNoShows"]) {
      hideStatuses.push(9); // no-show
    }

    return hideStatuses;
  }

  get events(): SmileCalendarEvent[] {
    const holidayCategoryEvents: SmileCalendarEvent[] = [];
    holidaysSmileCalendarEvent.forEach(holidayEvent => {
      if (this.calendarType === "category") {
        this.calendarCategories.forEach(category => {
          holidayCategoryEvents.push({
            ...holidayEvent,
            name: this.$t(`holidays.${holidayEvent.name}`).toString(),
            category,
          });
        });
      } else {
        holidayCategoryEvents.push({
          ...holidayEvent,
          name: this.$t(`holidays.${holidayEvent.name}`).toString(),
        });
      }
    });

    return [
      ...holidayCategoryEvents,
      ...this.usersBirthDayEvents,
      ...this.appointments
        .filter(({ status_id }) => (this.isDisplayCanceled || !(this.hideCancelStatuses.includes(status_id))))
        .map(appointment => ({
          appointment,
          category: this.userName(appointment.user_id),
          patient: appointment.patient,
          name: appointment.patient ? appointment.patient.first_name + " " + appointment.patient.last_name : appointment.notes,
          start: appointment.start_date,
          end: appointment.end_date,
          timed: true,
        })),
    ];
  }
}
