




















































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { PatientTreatment } from "@/modules/patient/types/patient-treatment.type";
import { ALL_TEETH, TEETH_SURFACES } from "@/modules/treatment/constants/denture-map";
import PatientTreatmentApi from "@/modules/patient/api/patient-treatment.api";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import { Patient } from "../types/patient.type";
import { age, date } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { PATIENT_ROUTE_NAME } from "../constants/route-config";
import { PlanTreatment } from "@/modules/treatment/types/plan-treatment.type";
import { User } from "@/modules/users/types/user.type";
import { PriceList, Treatment } from "@/modules/treatment/types/treatment.type";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import TrackChangesPatientTreatmentDialog from "./TrackChangesPatientTreatmentDialog.vue";
import DentalDirectSurface from "@/modules/treatment/components/form-inputs/DentalDirectSurface.vue";

@Component({
  components: {
    SentenceMenu,
    TrackChangesPatientTreatmentDialog,
    DentalDirectSurface
  },
  filters: {
    age,
    date,
  },
})
export default class PatientTreatmentFormDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Prop({ required: false }) public editTreatment: PatientTreatment;
  @Prop({ required: false }) public planTreatment: PlanTreatment;
  @Prop({ required: false, default: null }) public appointmentId: number;
  @Prop({ default: false }) viewOnly: boolean;
  @Prop({ default: false }) showCreateLetterButton: boolean;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isShowingTreatmentCode") isShowingTreatmentCode: boolean;
  @Getter("auth/trackChangesPatientTreatment") trackChangesPatientTreatment: boolean;
  @Getter("auth/treatmentAttributesType") treatmentAttributesType: string;
  @Getter("auth/treatmentShowQuantity") treatmentShowQuantity: boolean;
  @Getter("auth/priceLists") priceLists: PriceList[];
  public userId: number = null;
  public priceListId = 1; // TODO default pricelist
  public missingTreatmentError = false;
  public invalidPriceError = false;
  public invalidDiscountError = false;
  public formLoading = false;
  public deleteLoading = false;
  public isActionsLoading = false;
  public treatmentId: number = null;
  public showDiscount = false;
  public createdAtDate: string = moment().utc().format("YYYY-MM-DD");
  public createdAtTime: string = moment().format("HH:mm");
  public price = 0;
  public inputQuantityKey = 0;
  public quantity: number = null;
  public totalPrice = 0;
  public discountPercent = 0;
  public teeth: number[] = [];
  public surface: string[] = [];
  public color = "#FFFFFF";
  public notes = "";
  public displayTreatmentDatePicker = false;
  public displayTrackChanges = false;

  public onTrackChangesClicked() {
    this.displayTrackChanges = true;
  }

  public setSurfaceValue(selectedSurface: string[]) {
    this.surface = selectedSurface;
  }

  public appendSentence(sentence) {
    if (!this.notes) {
      this.notes = sentence;
      return;
    }

    this.notes += " " + sentence;
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  private fixIfTreatmentDeleted(id: number) {
    const selectedTreatment = this.$store.getters["auth/treatments"]
      .find(treatment => (treatment.id === id));

    if (selectedTreatment.deleted_at === null) {
      return;
    }

    this.notes = selectedTreatment.name + "\n" + this.notes;
    this.treatmentId = null;

    return selectedTreatment.deleted_at !== null;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  @Watch("quantity")
  public onQuantityChanged(newQuantity: string, oldQuantity: number) {
    if (!oldQuantity || !parseInt(oldQuantity.toString())) {
      return;
    }

    if (!parseInt(newQuantity)) {
      this.quantity = oldQuantity;
      this.inputQuantityKey++;
      return;
    }

    const pricePerOne = this.price / oldQuantity;
    const totalPricePerOne = this.totalPrice / oldQuantity;

    this.price = pricePerOne * this.quantity;
    this.totalPrice = totalPricePerOne * this.quantity;
  }

  mounted() {
    this.userId = this.$store.getters["auth/userId"];
    if (this.editTreatment) {
      this.createdAtDate = moment(this.editTreatment.created_at).format("YYYY-MM-DD");
      this.createdAtTime = moment(this.editTreatment.created_at).format("HH:mm");
      this.userId = this.editTreatment.user_id;
      this.priceListId = this.editTreatment.attributes && this.editTreatment.attributes.priceListId;
      this.price = this.editTreatment.price;
      this.quantity = this.editTreatment.quantity;
      if (this.editTreatment.price === 0 && this.editTreatment.discount) {
        this.totalPrice = 0;
        this.discountPercent = 100;
      } else {
        this.totalPrice = Number((this.editTreatment.price - this.editTreatment.discount).toFixed(2));
        this.discountPercent = Math.ceil(this.editTreatment.discount / this.editTreatment.price * 100) || 0;
      }

      this.showDiscount = this.editTreatment.discount != 0;
      this.notes = this.editTreatment.notes;
      this.treatmentId = this.editTreatment.treatment_id;
      this.teeth = this.editTreatment.attributes.teeth ? this.editTreatment.attributes.teeth.toString().split(",").map(teeth => parseInt(teeth)) : [];
      this.surface = this.editTreatment.attributes.surface ? this.editTreatment.attributes.surface.split(",") : [];
      if (this.editTreatment.color) {
        this.color = this.editTreatment.color;
      }

      this.fixIfTreatmentDeleted(this.editTreatment.treatment_id);
    } else if (this.planTreatment) {
      this.price = this.planTreatment.price;
      this.quantity = this.planTreatment.quantity;

      if (this.planTreatment.price === 0 && this.planTreatment.discount) {
        this.totalPrice = 0;
        this.discountPercent = 100;
      } else {
        this.totalPrice = Number((this.planTreatment.price - this.planTreatment.discount).toFixed(2));
        this.discountPercent = Math.ceil(this.planTreatment.discount / this.planTreatment.price * 100) || 0;
      }

      this.showDiscount = this.planTreatment.discount != 0;
      this.notes = this.planTreatment.notes;
      this.treatmentId = this.planTreatment.treatment_id;
      this.teeth = this.planTreatment.attributes.teeth ? this.planTreatment.attributes.teeth.toString().split(",").map(teeth => parseInt(teeth)) : [];
      this.surface = this.planTreatment.attributes.surface ? this.planTreatment.attributes.surface.split(",") : [];

      if (this.planTreatment.color) {
        this.color = this.planTreatment.color;
      }
    } else {
      this.quantity = 1;
    }
  }

  public treatmentsFilter(item: any, queryText: string) {
    return `${item.text} ${item.code}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }

  get showPriceList() {
    if (!this.treatmentId) {
      return false;
    }

    const selectedTreatment = (this.$store.getters["auth/treatments"] as Treatment[])
      .find(({ id }) => (id === this.treatmentId));

    if (!selectedTreatment) {
      return false;
    }

    return !!(selectedTreatment.attributes && selectedTreatment.attributes.priceList);
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async deleteTreatment() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.deleteLoading = true;
    try {
      await PatientTreatmentApi.delete(this.editTreatment.id);
      this.$emit("treatmentDeleted", this.editTreatment.id);
    } catch (err) {
      this.$toastr.e(this.$t("saving_error"));
    } finally {
      this.deleteLoading = false;
    }
  }

  public async submitCreateForm(emitCraeteLetterOnSuccess = false) {
    if (!this.treatmentId) {
      this.missingTreatmentError = true;
      return;
    }

    if (this.invalidDiscountError || this.invalidPriceError) {
      return;
    }

    const attempts = [];
    if (this.isTreatmentDuplicatePerTooth) {
      (this.teeth.length ? this.teeth : [undefined]).forEach(teeth => {
        const attributes = {
          priceListId: this.priceListId,
          teeth,
          surface: this.surface ? this.surface.join(",") : undefined,
        };

        this.formLoading = true;
        const newTreatmentDiscount = Math.floor(parseInt(this.price.toString()) * 0.01 * parseFloat(this.discountPercent.toString())) || 0;
        const createdAt = this.createdAtDate + " " + (this.createdAtTime ? this.createdAtTime + ":00" : "");
        const attempt = PatientTreatmentApi.create(this.patient.id, this.userId, this.appointmentId, this.treatmentId, attributes, this.price, newTreatmentDiscount, this.notes, this.color, createdAt, this.quantity);

        attempts.push(attempt);
        attempt
          .then(patientTreatment => {
            this.$emit("treatmentCreated", patientTreatment);
            if (emitCraeteLetterOnSuccess) {
              this.$emit("createLetter", patientTreatment);
            }
          })
          .catch(() => {
            this.formLoading = false;
            this.$toastr.e(this.$t("saving_error"));
          });
      });
    } else {
      const attributes = {
        priceListId: this.priceListId,
        teeth: this.teeth.length ? this.teeth.join(",") : undefined,
        surface: this.surface ? this.surface.join(",") : undefined,
      };

      this.formLoading = true;
      const newTreatmentDiscount = Math.floor(parseInt(this.price.toString()) * 0.01 * parseFloat(this.discountPercent.toString())) || 0;
      const createdAt = this.createdAtDate + " " + (this.createdAtTime ? this.createdAtTime + ":00" : "");
      const attempt = PatientTreatmentApi.create(this.patient.id, this.userId, this.appointmentId, this.treatmentId, attributes, this.price, newTreatmentDiscount, this.notes, this.color, createdAt, this.quantity);

      attempts.push(attempt);
      attempt
        .then(patientTreatment => {
          this.$emit("treatmentCreated", patientTreatment);
          if (emitCraeteLetterOnSuccess) {
            this.$emit("createLetter", patientTreatment);
          }
        })
        .catch(() => {
          this.formLoading = false;
          this.$toastr.e(this.$t("saving_error"));
        });
    }

    Promise.all(attempts)
      .then(() => {
        this.closeDialog();
      });
  }

  get dateFormatted() {
    return moment(this.createdAtDate).format("DD-MM-YYYY");
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public reformatTimeInput(str) {
    str = str.replace(/[^\d:]/g, ""); // clean

    // Add missing :
    if (str.length >= 3 && str.indexOf(":") === -1) {
      str = str.slice(0, Math.min(2, str.length - 2)) + ":" + str.slice(Math.min(2, str.length - 2));
    }

    let [hour, min] = str.split(":");
    hour = parseInt(hour || 0);
    min = parseInt(min || 0);
    if (hour > 23 || hour < 0) {
      hour = 0;
    }
    if (min > 60 || min < 0) {
      min = 0;
    }

    if (hour === 0 && min === 0) {
      return "";
    }

    return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min;
  }

  get isTreatmentDuplicatePerTooth() {
    if (!this.treatmentId) {
      return false;
    }

    if (this.planTreatment) {
      return false;
    }

    const treatment = (this.$store.getters["auth/treatments"] as Treatment[]).find(({ id }) => (id === this.treatmentId));
    return treatment.attributes && treatment.attributes.isDuplicatePerTooth;
  }

  public async submitUpdateForm(emitCraeteLetterOnSuccess = false) {
    if (!this.treatmentId) {
      this.missingTreatmentError = true;
      return;
    }

    if (this.invalidDiscountError || this.invalidPriceError) {
      return;
    }

    const attributes = {
      priceListId: this.priceListId,
      teeth: this.teeth.length ? this.teeth.join(",") : undefined,
      surface: this.surface ? this.surface.join(",") : undefined,
    };

    this.formLoading = true;
    const newTreatmentDiscount = Math.floor(parseInt(this.price.toString()) * 0.01 * parseFloat(this.discountPercent.toString())) || 0;
    const createdAt = this.createdAtDate + " " + (this.createdAtTime ? this.createdAtTime + ":00" : "");
    const attempt = PatientTreatmentApi.update(this.editTreatment.id, this.userId, this.appointmentId, this.treatmentId, attributes, this.price, newTreatmentDiscount, this.notes, this.color, createdAt, this.quantity);

    attempt
      .then(patientTreatment => {
        this.$emit("treatmentUpdated", patientTreatment);
        if (emitCraeteLetterOnSuccess) {
          this.$emit("createLetter", patientTreatment);
        }
        this.closeDialog();
      })
      .catch(() => {
        this.formLoading = false;
        this.$toastr.e(this.$t("saving_error"));
      });
  }

  get surfaceOptions() {
    return TEETH_SURFACES.map(surface => ({
      value: surface,
      text: surface
    })
    );
  }

  get teethOptions() {
    return ALL_TEETH
      .map(teeth => ({
        value: teeth,
        text: teeth
      })
      );
  }

  get treatmentOptions() {
    return this.$store.getters["auth/treatments"]
      .filter(treatment => !treatment.deleted_at)
      .map(treatment => ({
        "text": treatment.name,
        "code": treatment.code,
        "value": treatment.id
      }));
  }

  get userOptions() {
    return (this.$store.getters["auth/users"] as User[])
      .filter(({ deleted_at, id }) => (!deleted_at || id === this.userId || id === this.$store.getters["auth/userId"]))
      .filter(({ type, id }) => (type === "caregiver" || id === this.$store.getters["auth/userId"]))
      .map(user => ({
        "text": user.name,
        "value": user.id
      }));
  }

  public treatmentSelected() {
    this.missingTreatmentError = false;
    this.invalidPriceError = false;
    const selectedTreatment = (this.$store.getters["auth/treatments"] as Treatment[])
      .find(treatment => (treatment.id === this.treatmentId));

    if (!selectedTreatment) {
      return;
    }
    if (selectedTreatment.attributes && selectedTreatment.attributes.userPrice && selectedTreatment.attributes.userPrice[this.userId]) {
      this.price = selectedTreatment.attributes.userPrice[this.userId] * this.quantity;
    } else if (selectedTreatment.attributes && selectedTreatment.attributes.priceList && this.priceListId in selectedTreatment.attributes.priceList) {
      this.price = selectedTreatment.attributes.priceList[this.priceListId] * this.quantity;
    } else {
      this.price = selectedTreatment.price * this.quantity;
    }

    if (!this.discountPercent) {
      this.totalPrice = this.price;
    }

    if (!this.notes) {
      this.notes = selectedTreatment.default_notes;
    }

    if (selectedTreatment.color) {
      this.color = selectedTreatment.color;
    }

    if (this.showDiscount) {
      this.onDiscountPercentChanged(this.discountPercent.toString());
    }
  }

  public onPriceChanged(price: string) {
    this.invalidPriceError = (!parseFloat(price) && parseFloat(price) !== 0);
    if (!parseFloat(price)) {
      this.totalPrice = 0;
      return;
    }

    const discount = Math.floor(parseInt(price.toString()) * 0.01 * parseFloat(this.discountPercent.toString()));
    if (this.invalidDiscountError) {
      this.totalPrice = 0;
    } else if (discount) {
      this.totalPrice = Number((parseFloat(price) - discount).toFixed(2));
    } else {
      this.totalPrice = parseFloat(price.toString());
    }
  }

  public onDiscountPercentChanged(discountPercent: string) {
    this.invalidDiscountError = parseFloat(discountPercent) < 0 || parseFloat(discountPercent) > 100;
    const discount = Math.floor(parseInt(this.price.toString()) * 0.01 * parseFloat(discountPercent));
    if (this.invalidDiscountError) {
      this.totalPrice = 0;
    } else if (discount) {
      this.totalPrice = Number((this.price - discount).toFixed(2));
    } else {
      this.totalPrice = parseFloat(this.price.toString());
    }
  }

  public async onCreateLetterClicked() {
    this.isActionsLoading = true;
    if (this.editTreatment) {
      await this.submitUpdateForm(true);
    } else {
      await this.submitCreateForm(true);
    }
    this.isActionsLoading = false;
  }

  get colorOptions() {
    return [
      ["#FFFFFF", "#FD7B7B"],
      ["#FFFF9F", "#FBAEFF"],
      ["#B8FFB8", "#FFE787"],
      ["#81FFFF", "#C2C2FF"],
    ];
  }
}
