



























































































































import { Component, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import WaitingAppointmentApi from "../api/waiting-appointment.api";
import WaitingListFormDialog from "../components/waiting-appointment/WaitingListFormDialog.vue";
import { WaitingAppointment } from "../types/waiting-appointment.type";
import { date } from "@/filters/date.filter";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Getter } from "vuex-class";
import moment from "moment";
import { Appointment } from "../types/appointment.type";
import { DataTableHeader } from "vuetify";
import { Patient } from "@/modules/patient/types/patient.type";


@Component({
  components: {
    WaitingListFormDialog,
  },
  filters: {
    date,
  },
})
export default class WaitingListPage extends mixins(SmileMixin) {
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isRtl") isRtl: boolean;

  public dispalyUserId: number = null;
  public urgencyFilter: number = null;
  public dayInWeekFilter: number = null;
  public waitingAppointments: WaitingAppointment[] = [];
  public isLoading = false;
  public editWaitingAppointment: WaitingAppointment = null;
  public displayFormDialog = false;

  public waitingAppointmentUpdated() {
    this.reload();
  }

  public waitingAppointmentCreated() {
    this.reload();
  }

  public updateQueryStringFilters() {
    const query = {
      day: this.dayInWeekFilter || this.dayInWeekFilter === 0 ? this.dayInWeekFilter.toString() : undefined,
      urgency: this.urgencyFilter ? this.urgencyFilter.toString() : undefined,
      calendar: this.dispalyUserId ? this.dispalyUserId.toString() : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  public closeFormDialog() {
    this.editWaitingAppointment = null;
    this.displayFormDialog = false;
  }

  public openFormDialog(waitingAppointment: WaitingAppointment) {
    this.editWaitingAppointment = waitingAppointment;
    this.displayFormDialog = true;
  }

  public async mounted() {
    this.applyFromQueryStringFilters();

    if (!this.dispalyUserId && this.$store.getters["auth/isTherapist"]) {
      this.dispalyUserId = this.$store.getters["auth/userId"];
    }

    this.reload();
  }

  @Watch("dispalyUserId")
  @Watch("urgencyFilter")
  @Watch("dayInWeekFilter")
  public async reload() {
    this.updateQueryStringFilters();
    this.isLoading = true;

    try {
      this.waitingAppointments = await WaitingAppointmentApi.find();
      this.isLoading = false;
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoading = false;
    }
  }
  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  get headers() {
    const headers: DataTableHeader[] = [
      { text: this.$t("headers.created_at").toString(), value: "created_at" },
    ];

    if (!this.dispalyUserId) {
      headers.push({ text: this.$t("headers.user").toString(), value: "user_ids" });
    }

    headers.push(
      {
        text: this.$t("headers.patient_name").toString(), value: "patient", sort: (patientA: Patient, patientB: Patient) => {
          return (patientA.first_name + patientA.last_name).localeCompare(patientB.first_name + patientB.last_name);
        }
      },
      {
        text: this.$t("headers.phone").toString(), value: "patient.phone"
      },
      // { text: this.$t("headers.urgency"), value: "urgency" },
      // { text: this.$t("headers.days"), value: "days", sortable: false },
      // { text: this.$t("headers.duration"), value: "appointment_duration_minutes" },
      { text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
      { text: this.$t("headers.last_appointment").toString(), value: "last_appointment", sortable: false },
      { text: this.$t("headers.next_appointments").toString(), value: "next_appointments", sortable: false },
      { text: "", value: "actions", sortable: false }
    );
    return headers;
  }

  public onWaitingAppointmentDeleted(waitingAppointmentId: number) {
    const index = this.waitingAppointments.findIndex(({ id }) => (id === waitingAppointmentId));
    if (index || index === 0) {
      this.waitingAppointments.splice(index, 1);
    }
    this.closeFormDialog();
  }

  public getDays(waitingAppointment: WaitingAppointment) {
    const mapToDay = {
      availability_sunday: "א",
      availability_monday: "ב",
      availability_tuesday: "ג",
      availability_wednesday: "ד",
      availability_thursday: "ה",
      availability_friday: "ו",
      availability_saturday: "ש",
    };

    const days = [];
    Object.keys(mapToDay).forEach(function (day) {
      if (waitingAppointment[day] && waitingAppointment[day].length) {
        days.push(mapToDay[day]);
      }
    });

    return days.join(",");
  }

  public rowClass(waitingAppointment: WaitingAppointment) {
    if (this.editWaitingAppointment && waitingAppointment.id === this.editWaitingAppointment.id) {
      return "cyan lighten-5";
    }
  }

  public applyFromQueryStringFilters() {
    const queryCalendar = parseInt(this.$route.query.calendar && this.$route.query.calendar.toString());
    const queryUrgency = parseInt(this.$route.query.urgency && this.$route.query.urgency.toString());
    const queryDay = parseInt(this.$route.query.day && this.$route.query.day.toString());

    if (queryCalendar) {
      this.dispalyUserId = queryCalendar;
    }

    if (queryUrgency) {
      this.urgencyFilter = queryUrgency;
    }

    if (queryDay) {
      this.dayInWeekFilter = queryDay;
    }
  }

  public lastAppointment(appointments: Appointment[]) {
    const today = moment().startOf("day");

    const pastAppointments = appointments
      .filter(appointment => today.diff(appointment.start_date, "hour") > 0)
      .filter(appointment => [1, 2, 3, 4, 5, 6, 7].includes(appointment.status_id))
      .sort((appointmentA, appointmentB) => (appointmentB.start_date.localeCompare(appointmentA.start_date)));

    if (!pastAppointments.length) {
      return "-";
    }

    return this.relativeDate(pastAppointments[0].start_date) + " " + this.userName(pastAppointments[0].user_id);
  }

  public nextAppointments(appointments: Appointment[]): Appointment[] {
    const today = moment().startOf("day");

    const futureAppointments = appointments
      .filter(appointment => today.diff(appointment.start_date, "hour") <= 0)
      .filter(appointment => [1, 2, 3, 4, 5, 6, 7].includes(appointment.status_id))
      .sort((appointmentA, appointmentB) => (appointmentA.start_date.localeCompare(appointmentB.start_date)));

    if (!futureAppointments.length) {
      return [];
    }

    return futureAppointments;
  }

  get displayWaitingAppointments() {
    return this.waitingAppointments
      .filter(({ user_ids }) => (user_ids[0] === this.dispalyUserId || !this.dispalyUserId))
      .filter(({ urgency }) => (urgency === this.urgencyFilter || !this.urgencyFilter))
      .filter(waitingAppointment => {
        if (this.dayInWeekFilter === null) {
          return true;
        }

        switch (this.dayInWeekFilter) {
          case 0:
            if (waitingAppointment.availability_sunday.length) {
              return true;
            }
            break;
          case 1:
            if (waitingAppointment.availability_monday.length) {
              return true;
            }
            break;
          case 2:
            if (waitingAppointment.availability_tuesday.length) {
              return true;
            }
            break;
          case 3:
            if (waitingAppointment.availability_wednesday.length) {
              return true;
            }
            break;
          case 4:
            if (waitingAppointment.availability_thursday.length) {
              return true;
            }
            break;
          case 5:
            if (waitingAppointment.availability_friday.length) {
              return true;
            }
            break;
          case 6:
            if (waitingAppointment.availability_saturday.length) {
              return true;
            }
            break;
        }
      });
  }

  get urgencyOptions() {
    return [3, 2, 1]
      .map(urgency => ({
        "text": this.$t("urgency." + urgency),
        "value": urgency
      }));
  }

  get daysOptions() {
    return [0, 1, 2, 3, 4, 5, 6]
      .map(urgency => ({
        "text": this.$t("day-in-week." + urgency),
        "value": urgency
      }));
  }

  get therapistUsers() {
    return [
      ...this.$store.getters["auth/therapistUsers"]
        .map(user => ({
          "text": user.name,
          "value": user.id
        }))
    ];
  }
}
