
















































































































































































































































































import AppointmentApi from "@/modules/appointment/api/appointment.api";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { age, date, time } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Appointment } from "@/modules/appointment/types/appointment.type";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import PatientWhatsappChatDialog from "@/modules/whatsapp/components/PatientWhatsappChatDialog.vue";
import WhatsappApi from "@/modules/whatsapp/api/whatsapp.api";
import { Patient } from "@/modules/patient/types/patient.type";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import EditAppointmentFormDialog from "@/modules/appointment/components/appointment/EditAppointmentFormDialog.vue";

@Component({
  components: {
    ErrorLogo,
    EditAppointmentFormDialog,
    PatientWhatsappChatDialog,
  },
  filters: {
    age,
    date,
    time
  }
})
export default class NotificationsCenter extends mixins(SmileMixin) {
  @Getter("auth/clinicName") clinicName: string;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;

  public loadingPhoneNumber = null;
  public isSendingAll = false;
  public loadErrors: any = false;
  public loading = false;
  public appointments: Appointment[] = [];
  public dispalyUserId: number = null;
  public dataTableOptions: any = {};
  public dateFilter = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
  public dateMenuOpen = false;
  public reminderSentAppointmentIds = [];
  public followUpSentAppointmentIds = [];
  public whatsappDisaplyChatPhone: string = null;
  private activeRequest: CancelTokenSource = null;
  public editAppointment: Appointment = null;

  public async onAppointmentDeleted() {
    await this.reload();
  }

  public async onAppointmentUpdated() {
    await this.reload();
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get isSendingAllAvailable() {
    return this.isWhatsappBotEnabled && this.appointments.length && this.readyToSendAppointments.length;
  }

  get shouldShowFollowUpButton() {
    const diffDays = moment().diff(moment(this.dateFilter), "days");
    const whatsappWebFollowUpTemplate = this.$store.getters["auth/whatsappWebFollowUpTemplate"];
    return (diffDays <= 14) && whatsappWebFollowUpTemplate;
  }

  get readyToSendAppointments() {
    return this.appointments
      .filter(({ status_id, patient }) => (status_id === 1 && patient.phone))
      .filter(({ lastMessageSentAt, isReminderSent, isReminderUnseen }) => ((!isReminderSent) || (isReminderSent && !isReminderUnseen && this.momentHoursDiff(lastMessageSentAt) > 16)));
  }

  public async onSendAllClicked() {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_reminder_confirm_message").toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_reminder_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_reminder_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    await this.reload();

    try {
      this.isSendingAll = true;
      const sentPhones: string[] = [];

      for (const appointment of this.readyToSendAppointments) {
        if (sentPhones.includes(appointment.patient.phone)) {
          continue;
        }
        const date = moment(appointment.start_date).format("YYYY-MM-DD");
        await WhatsappApi.sendAppointmentReminderMessage(appointment.patient.phone, date);
        sentPhones.push(appointment.patient.phone);
      }
      this.isSendingAll = false;
      this.$toastr.s(this.$t("whatsapp_bot_reminder_sent"));
      await this.reload();
    } catch (err) {
      this.isSendingAll = false;
      this.$toastr.e(this.$t("unexpected_error") + " " + err);
      await this.reload();
    }
  }

  public closeWhatsappChatDialog() {
    this.whatsappDisaplyChatPhone = null;
  }

  get headers() {
    const headers = [];

    if (!this.dispalyUserId) {
      headers.push({ text: this.$t("headers.user"), value: "user", sortable: false });
    }

    headers.push(
      { text: this.$t("headers.start_time"), value: "start_time", sortable: false },
      // {text: this.$t("headers.end_time"), value: "end_time", sortable: false},
      { text: this.$t("headers.name"), value: "name", sortable: false },
      { text: this.$t("headers.notes"), value: "notes", sortable: false },
      { text: this.$t("headers.phone"), value: "phone", sortable: false },
    );

    if (["מרפאת אליצור", "מרפאה לדוגמה", "Modiin Dental"].includes(this.clinicName)) {
      headers.push(
        { text: this.$t("headers.id_number"), value: "patient.id_number", sortable: false },
      );
    }

    // if (this.isWhatsappBotEnabled) {
    //   headers.push(
    //     { text: this.$t("headers.reminder_notes"), value: "reminder_notes", sortable: false },
    //   );
    // }

    headers.push(
      { text: this.$t("headers.status"), value: "status", sortable: false },
    );

    return headers;
  }

  public rowClass(appointment: Appointment) {
    if (appointment.patient.phone && appointment.patient.phone === this.whatsappDisaplyChatPhone) {
      return "cyan lighten-5";
    }
  }

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public getMultipleAppointments(appointment: Appointment) {
    const cleanPhone = appointment.patient.phone.replace("-", "");
    const dayAppointments = this.appointments.filter(({ patient }) => (patient.phone && patient.phone.replace("-", "") === cleanPhone));

    return dayAppointments;
  }

  get missingPhoneNotApprovedAppointments() {
    return this.displayAppointments.filter(({ patient }) => (!patient || !patient.phone)).filter(({ status_id }) => (status_id === 1));
  }

  public async sendMultipleReminders(appointment: Appointment) {
    if (this.isWhatsappBotEnabled) {
      await this.sendMultipleRemindersWhatsappBot(appointment);
    } else {
      this.sendMultipleRemindersWeb(appointment);
    }
  }

  public sendMultipleRemindersWeb(appointment: Appointment) {
    const dayAppointments = this.getMultipleAppointments(appointment);
    const uniqueFirstNames = [...new Set(dayAppointments.map(({ patient }) => (patient.first_name)))];
    const firstNamesText = uniqueFirstNames.length === 1 ? uniqueFirstNames[0] : uniqueFirstNames.slice(0, -1).join(", ") + " ו" + uniqueFirstNames.slice(-1).join("");
    const firstNamesTextEnglish = uniqueFirstNames.length === 1 ? uniqueFirstNames[0] : uniqueFirstNames.slice(0, -1).join(", ") + " and " + uniqueFirstNames.slice(-1).join("");
    const firstNamesTextCommas = uniqueFirstNames.length === 1 ? uniqueFirstNames[0] : uniqueFirstNames.join(", ");
    const uniqueTimes = [...new Set(dayAppointments.map(({ start_date }) => (moment(start_date).format("HH:mm"))))];
    const uniqueUsers = [...new Set(dayAppointments.map(({ user_id }) => (this.userName(user_id))))];
    const usersText = uniqueUsers.length === 1 ? uniqueUsers[0] : uniqueUsers.slice(0, -1).join(", ") + " ו" + uniqueUsers.slice(-1).join("");
    const usersTextEnglish = uniqueUsers.length === 1 ? uniqueUsers[0] : uniqueUsers.slice(0, -1).join(", ") + " and " + uniqueUsers.slice(-1).join("");
    const usersTextCommas = uniqueUsers.length === 1 ? uniqueUsers[0] : uniqueUsers.join(", ");
    const dayInWeek = moment(appointment.start_date, undefined, "he").format("dddd");
    const dayInWeekRussian = moment(appointment.start_date, undefined, "ru").format("dddd")
      .replaceAll("воскресенье", "воскресенье")
      .replaceAll("понедельник", "понедельник")
      .replaceAll("вторник", "вторник")
      .replaceAll("среда", "среду")
      .replaceAll("четверг", "четверг")
      .replaceAll("пятница", "пятницу")
      .replaceAll("суббота", "субботу");

    const dayInWeekEnglish = moment(appointment.start_date, undefined, "en").format("dddd");
    const date = moment(appointment.start_date).format("D/M");
    const dateEnglish = moment(appointment.start_date, undefined, "en").format("D MMM");
    const time = uniqueTimes.sort()[0];
    const message = this.$store.getters["auth/whatsappWebMultipleRemindersTemplate"]
      .replaceAll("{patientFirstName}", appointment.patient.first_name)
      .replaceAll("{firstNamesText}", firstNamesText)
      .replaceAll("{firstNamesTextEnglish}", firstNamesTextEnglish)
      .replaceAll("{calendarName}", usersText)
      .replaceAll("{calendarNameEnglish}", usersTextEnglish)
      .replaceAll("{dayInWeek}", dayInWeek)
      .replaceAll("{dayInWeekRussian}", dayInWeekRussian)
      .replaceAll("{dayInWeekEnglish}", dayInWeekEnglish)
      .replaceAll("{firstNamesTextCommas}", firstNamesTextCommas)
      .replaceAll("{usersTextCommas}", usersTextCommas)
      .replaceAll("{date}", date)
      .replaceAll("{dateEnglish}", dateEnglish)
      .replaceAll("{time}", time);

    this.markReminderSent(appointment);
    this.shareWhatsapp(appointment.patient.phone, message);
  }

  public async sendMultipleRemindersWhatsappBot(appointment: Appointment) {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_reminder_confirm_message").toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_reminder_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_reminder_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    const date = moment(appointment.start_date).format("YYYY-MM-DD");
    try {
      this.loadingPhoneNumber = appointment.patient.phone;
      await WhatsappApi.sendAppointmentReminderMessage(appointment.patient.phone, date);
      this.loadingPhoneNumber = null;

      this.appointments.forEach((a) => {
        if (a.patient && a.patient.phone && a.patient.phone === appointment.patient.phone) {
          a.isReminderSent = true;
          a.lastMessageSentAt = moment().endOf("day").format("YYYY-MM-DD HH:mm");
        }
      });

      this.$toastr.s(this.$t("whatsapp_bot_reminder_sent"));
    } catch (err) {
      this.loadingPhoneNumber = null;
      this.$toastr.e(this.$t("unexpected_error") + " " + err);
    }
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  public async sendReminder(appointment: Appointment) {
    if (this.isWhatsappBotEnabled) {
      await this.sendReminderWhatsappBot(appointment);
    } else {
      this.sendReminderWeb(appointment);
    }
  }

  public sendReminderWeb(appointment: Appointment) {
    const dayInWeek = moment(appointment.start_date, undefined, "he").format("dddd");
    const dayInWeekRussian = moment(appointment.start_date, undefined, "ru").format("dddd")
      .replaceAll("воскресенье", "воскресенье")
      .replaceAll("понедельник", "понедельник")
      .replaceAll("вторник", "вторник")
      .replaceAll("среда", "среду")
      .replaceAll("четверг", "четверг")
      .replaceAll("пятница", "пятницу")
      .replaceAll("суббота", "субботу");

    const dayInWeekEnglish = moment(appointment.start_date, undefined, "en").format("dddd");
    const date = moment(appointment.start_date).format("D/M");
    const dateEnglish = moment(appointment.start_date, undefined, "en").format("MMM Do");
    const time = moment(appointment.start_date).format("HH:mm");
    const message = this.$store.getters["auth/whatsappWebSingleReminderTemplate"]
      .replaceAll("{patientFirstName}", appointment.patient.first_name)
      .replaceAll("{calendarName}", this.userName(appointment.user_id))
      .replaceAll("{dayInWeek}", dayInWeek)
      .replaceAll("{dayInWeekRussian}", dayInWeekRussian)
      .replaceAll("{dayInWeekEnglish}", dayInWeekEnglish)
      .replaceAll("{date}", date)
      .replaceAll("{dateEnglish}", dateEnglish)
      .replaceAll("{time}", time);

    this.markReminderSent(appointment);
    this.shareWhatsapp(appointment.patient.phone, message);
  }

  public async sendReminderWhatsappBot(appointment: Appointment) {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_reminder_confirm_message").toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_reminder_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_reminder_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }
    const date = moment(appointment.start_date).format("YYYY-MM-DD");
    try {
      this.loadingPhoneNumber = appointment.patient.phone;
      await WhatsappApi.sendAppointmentReminderMessage(appointment.patient.phone, date);
      this.loadingPhoneNumber = null;

      this.appointments.forEach((a) => {
        if (a.patient && a.patient.phone && a.patient.phone === appointment.patient.phone) {
          a.isReminderSent = true;
          a.lastMessageSentAt = moment().endOf("day").format("YYYY-MM-DD HH:mm");
        }
      });

      this.$toastr.s(this.$t("whatsapp_bot_reminder_sent"));
    } catch (err) {
      this.loadingPhoneNumber = null;
      this.$toastr.e(this.$t("unexpected_error") + " " + err);
    }
  }

  public openWhatsappChat(appointment: Appointment) {
    this.whatsappDisaplyChatPhone = appointment.patient.phone;
  }

  get formattedDateFilter() {
    const [year, month, day] = this.dateFilter.split("-");
    return `${day}/${month}/${year}`;
  }

  get isDateShabat() {
    return parseInt(moment(this.dateFilter).format("E")) === 6;
  }

  get tags() {
    return this.$store.getters["auth/tags"]
      .map(tag => ({
        "text": tag.name,
        "value": tag.id
      }));
  }

  get displayAppointments() {
    if (!this.dispalyUserId) {
      return this.appointments;
    }

    return this.appointments.filter(({ user_id }) => (user_id === this.dispalyUserId));
  }

  public async reload() {
    this.updateQueryStringFilters();
    this.loading = true;
    this.appointments = [];
    const displayStatusIds = [1, 2, 3, 4, 5, 6, 7]; // No `canceled` / `no-show`

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    return AppointmentApi
      .findNotificationsCenter(this.dateFilter, null, displayStatusIds, this.activeRequest)
      .then(response => {
        this.loadErrors = false;
        this.appointments = response;
        this.loading = false;

      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return;
        }

        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.loadErrors = err.response.data.errors;
        } else {
          this.loadErrors = this.$t("temporary_error").toString();
        }
      });
  }

  get therapistUsers() {
    return [
      ...this.$store.getters["auth/therapistUsers"]
        .map(user => ({
          "text": user.name,
          "value": user.id
        }))
    ];
  }

  @Watch("dispalyUserId")
  @Watch("dateFilter")
  async onFiltersChanged() {
    await this.reload();
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  public applyFromQueryStringFilters() {
    const queryDate = this.$route.query.date && this.$route.query.date.toString();
    const queryCalendar = parseInt(this.$route.query.calendar && this.$route.query.calendar.toString());

    if (queryDate) {
      this.dateFilter = queryDate;
    }
    if (queryCalendar) {
      this.dispalyUserId = queryCalendar;
    }
  }

  public momentHoursDiff(date: string) {
    return moment().diff(date, "hours");
  }

  public updateQueryStringFilters() {
    const query = {
      date: this.dateFilter,
      calendar: this.dispalyUserId ? this.dispalyUserId.toString() : undefined,
    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  public filterTherapistUserByDefault() {
    if (this.dispalyUserId) {
      return;
    }

    if (!this.$store.getters["auth/isTherapist"]) {
      return;
    }

    this.dispalyUserId = this.$store.getters["auth/userId"];
  }

  public async mounted() {
    this.applyFromQueryStringFilters();
    this.filterTherapistUserByDefault();
    await this.reload();
  }

  public findFamilyByLastName(lastName: string, excludePatientId: number) {
    const patients: Patient[] = [];

    this.appointments.forEach(appointment => {
      if (appointment.patient && appointment.patient.phone && appointment.patient.id !== excludePatientId && lastName === appointment.patient.last_name) {
        patients.push(appointment.patient);
      }
    });

    return patients;
  }

  public async onSendFastInstructionsClicked(appointment: Appointment) {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_fast_instructions_confirm_message", { name: appointment.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_fast_instructions_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_fast_instructions_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.loadingPhoneNumber = appointment.patient.phone;
      if (appointment.user_id === 10) { // esti
        await WhatsappApi.sendFastInstructionsEsti(appointment.patient.phone, appointment.patient.first_name);
      } else {
        await WhatsappApi.sendFastInstructionsKido(appointment.patient.phone, appointment.patient.first_name);
      }

      this.appointments.forEach((a) => {
        if (a.patient_id === appointment.patient_id) {
          a.lastFastMessageSentAt = moment().endOf("day").format("YYYY-MM-DD HH:mm");
        }
      });
      this.$toastr.s(this.$t("whatsapp_bot_fast_instructions_sent"));
    } catch (err) {
      this.$toastr.e(err);
    } finally {
      this.loadingPhoneNumber = null;
    }
  }

  public async onSendQuestionnaireChildClicked(appointment: Appointment) {
    const res = await this.$confirm(
      this.$t("whatsapp_bot_questionnaire_child_confirm_message", { name: appointment.patient.first_name }).toString(),
      {
        buttonTrueColor: "cyan darken-2",
        buttonTrueText: this.$t("whatsapp_bot_questionnaire_child_confirm_ok_btn").toString(),
        buttonFalseText: this.$t("whatsapp_bot_questionnaire_child_confirm_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      this.loadingPhoneNumber = appointment.patient.phone;
      await WhatsappApi.sendQuestionnaireChildMessage(appointment.patient.phone, appointment.patient.first_name);
      this.appointments.forEach((a) => {
        if (a.patient_id === appointment.patient_id) {
          a.lastQuestionnaireSent = moment().endOf("day").format("YYYY-MM-DD HH:mm");
        }
      });
      this.$toastr.s(this.$t("whatsapp_bot_questionnaire_child_sent"));
    } catch (err) {
      this.$toastr.e(err);
    } finally {
      this.loadingPhoneNumber = null;
    }
  }

  public shouldDisplaySendQuestionnaireButton(appointment: Appointment) {
    if (this.clinicName !== "מרפאת קידו") {
      return false;
    }

    if (appointment.lastQuestionnaireSent || !appointment.patient.phone) {
      return false;
    }

    if (appointment.start_date < moment().format("YYYY-MM-DD HH:mm")) {
      return false;
    }

    if (appointment.status_id !== 3 && appointment.status_id !== 4) {
      return false;
    }

    const pastAppointments = appointment.patient.appointments.filter(({ start_date }) => (start_date < moment().startOf("day").format("YYYY-MM-DD HH:mm")));
    if (pastAppointments.length) {
      return false;
    }

    return true;
  }

  public shouldDisplaySendFastButton(appointment: Appointment) {
    if (this.clinicName !== "מרפאת קידו") {
      return false;
    }

    if (!appointment.patient.phone) {
      return false;
    }

    if (appointment.lastFastMessageSentAt > moment().add(-5, "day").format("YYYY-MM-DD HH:mm")) {
      return false;
    }

    if (appointment.start_date < moment().format("YYYY-MM-DD HH:mm")) {
      return false;
    }

    if (appointment.status_id !== 3 && appointment.status_id !== 4) {
      return false;
    }

    const keywords = ["צום", "טיפול", "עקירה"];
    if (keywords.filter(keyword => (appointment.notes && appointment.notes.includes(keyword))).length) {
      return true;
    }

    const durationMinutes = moment
      .duration(moment(appointment.end_date).diff(appointment.start_date))
      .minutes();

    if (appointment.user_id === 10 && durationMinutes >= 30) { // אסתי
      return true;
    }

    if ((appointment.user_id === 7 || appointment.user_id === 4) && durationMinutes >= 40) { // ליאת יעל
      return true;
    }

    if (appointment.user_id === 8 && durationMinutes >= 45) { // נועה 
      return true;
    }
  }

  public isReminderSentWhatsappWeb(appointment: Appointment) {
    if (this.isWhatsappBotEnabled) {
      return false;
    }
    const localStorageIds = localStorage.getItem("reminded-appointment-ids");
    if (!localStorageIds) {
      return false;
    }

    const sentAppointmentIds = new Set([
      ...localStorageIds.split(","),
      ...this.reminderSentAppointmentIds,
    ]);

    return sentAppointmentIds.has(appointment.id.toString());
  }

  public isFollowUpSentWhatsappWeb(appointment: Appointment) {
    const localStorageIds = localStorage.getItem("followed-up-appointment-ids");
    if (!localStorageIds) {
      return false;
    }
    const sentAppointmentIds = new Set([
      ...localStorageIds.split(","),
      ...this.reminderSentAppointmentIds,
    ]);
    return sentAppointmentIds.has(appointment.id.toString());
  }

  public markReminderSent(appointment: Appointment) {
    const localStorageIds = localStorage.getItem("reminded-appointment-ids") || "";
    const sentAppointmentIds = localStorageIds.split(",");
    sentAppointmentIds.push(appointment.id.toString());
    this.reminderSentAppointmentIds = sentAppointmentIds;
    localStorage.setItem("reminded-appointment-ids", sentAppointmentIds.join(","));
  }

  public onSendFollowupMessage(phone: string, appointmentId: number) {
    const whatsappWebFollowUpTemplate = this.$store.getters["auth/whatsappWebFollowUpTemplate"];
    this.shareWhatsapp(phone, whatsappWebFollowUpTemplate);
    this.markFollowUpSent(appointmentId);
  }

  public markFollowUpSent(appointmentId: number) {
    const localStorageIds = localStorage.getItem("followed-up-appointment-ids") || "";
    const sentAppointmentIds = localStorageIds.split(",");
    sentAppointmentIds.push(appointmentId.toString());
    this.followUpSentAppointmentIds = sentAppointmentIds;
    localStorage.setItem("followed-up-appointment-ids", sentAppointmentIds.join(","));
  }

}
