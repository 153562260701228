












































import { Component, Prop, Vue } from "vue-property-decorator";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";
import ContactBookmarkApi from "@/modules/contacts/api/contact-bookmark.api";
import { AxiosError } from "axios";
import get from "lodash/get";
import ErrorMessage from "@/components/layout/ErrorMessage.vue";

@Component({
  components: {
    ErrorMessage
  }
})
export default class BookmarkForm extends Vue {
  @Prop({ required: false }) public bookmark: ContactBookmark;
  public errors: any = false;
  public saveLoading = false;
  public deleteLoading = false;
  public name = "";
  public displayType: string[] = [];
  public order = 0;

  get editMode(): boolean {
    return Boolean(this.bookmark);
  }

  public mounted() {
    if (this.bookmark) {
      this.name = this.bookmark.name;
      this.displayType = this.bookmark.display_type;
      this.order = this.bookmark.order;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async deleteClicked() {

    this.errors = false;

    const res = await this.$confirm(
      this.$t("delete_confirm_message", { "name": this.bookmark.name }).toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.deleteLoading = true;
    ContactBookmarkApi
      .delete(this.bookmark.id)
      .then(() => {
        this.$toastr.s(this.$t("deleted_toastr"));
        this.$emit("reload", this.bookmark.id);
        this.closeDialog();
      })
      .catch((error: AxiosError) => {
        const errorMessage = get(error, "response.data.errors", null);
        this.errors = errorMessage || this.$t("delete_error");
      })
      .finally(() => this.deleteLoading = false);
  }

  get displayTypeOptions() {
    const options = [
      {
        value: "shareFile",
        text: this.$t("display_type_share_file"),
      },
      {
        value: "reference",
        text: this.$t("display_type_reference"),
      },
      {
        value: "insurance",
        text: this.$t("display_type_insurance"),
      },
    ];

    if (this.$store.getters["auth/isShowingXrayReference"]) {
      options.push({
        value: "xrayReference",
        text: this.$t("display_type_xray_reference"),
      });
    }

    return options;
  }

  public save() {
    this.errors = false;
    this.saveLoading = true;

    const savingAttempt = this.editMode
      ? ContactBookmarkApi
        .update(this.bookmark.id, this.name, this.displayType, this.order)
      : ContactBookmarkApi
        .create(this.name, this.displayType, this.order);

    savingAttempt
      .then(() => {
        this.$emit("reload");
        this.saveLoading = false;
        this.closeDialog();
      })
      .catch(err => {
        this.saveLoading = false;
        this.errors = err.response.data.errors;
      });
  }

}
