































import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ContactBookmark } from "../types/contact-bookmark.type";
import { Getter } from "vuex-class";

@Component({})

export default class EmailContactSearch extends mixins(SmileMixin) {
  @Prop({ required: true }) public inputLabel: string;
  @Prop({ required: false }) public isMarkedSendEmail: boolean;
  @Prop({ required: false }) displayType: string;
  @Prop({ required: false }) isFullWidth: string;
  @Prop({ required: false }) currentEmail: string;
  @Getter("auth/isRtl") public isRtl: boolean;

  public email = "";
  public isAutoComplete = false;
  public inputTextDirection = "";
  public isInvalidEmail = false;
  @Watch("currentEmail")
  public updateEmailFromProp() {
    this.email = this.currentEmail;
  }

  public onEmailInputBlur() {
    this.isAutoComplete = false;

    if (this.email) {

      if (!this.isValidEmail(this.email)) {
        this.isInvalidEmail = true;
        return;
      } else {
        this.isInvalidEmail = false;
      }

      this.$emit("updateEmail", this.email);
      this.$emit("emailMarked");
    }
  }

  public advancedContactSearch(item: any, queryText: string, itemText: string) {
    const queries = queryText.split(" ").map((subText) => {
      return itemText.includes(subText);
    });
    return !queries.includes(false);

  }

  public markEmail() {
    this.$emit("emailMarked");
  }

  get contacts() {
    if (!this.$store.getters["auth/contacts"]) {
      return [];
    }
    const contacts = this.$store.getters["auth/contacts"] as ContactBookmark[];
    const flattenContacts = [];

    contacts.filter(contactBookmark => (!this.displayType || (contactBookmark.display_type && contactBookmark.display_type.includes(this.displayType))))
      .map(contactBookmark => {
        contactBookmark.contacts.map((contact) => {
          if (contact.email) {
            if (contacts.length > 1) {
              flattenContacts.push({ category: contactBookmark.name, value: contact.email, text: contact.name });
            } else {
              flattenContacts.push({ value: contact.email, text: contact.name });
            }
          }
        }
        );

      });

    return flattenContacts;
  }
  mounted() {
    this.inputTextDirection = this.isRtl ? "rtl" : "ltr";
  }
}

