import {
  REPORT_ALL_INCOMES_ROUTE_NAME,
  REPORT_ALL_RECEIPTS_ROUTE_NAME,
  REPORTS_ROUTE_NAME,
} from "@/modules/reports/constants/route-config";
import { Route } from "vue-router";
import store from "@/store";
import { PROFILE_ROUTE_NAME } from "@/modules/auth/constants/route-config";
import { settingsGuardMiddleware } from "@/modules/settings/routes/settings-guard-middleware";

const ALLOWED_ACCOUNTANT_ROUTE_NAMES = [
  REPORTS_ROUTE_NAME,
  REPORT_ALL_RECEIPTS_ROUTE_NAME,
  REPORT_ALL_INCOMES_ROUTE_NAME,
  PROFILE_ROUTE_NAME,
];

export const guardMiddleware = (to: Route, from: Route, next) => {
  if (store.getters["auth/isAccountant"]) {
    if (!ALLOWED_ACCOUNTANT_ROUTE_NAMES.includes(to.name)) {
      return next({ name: REPORTS_ROUTE_NAME });
    }
  }
  settingsGuardMiddleware(to, from, next);
};
