import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import {
  SupplierPaymentCategoryApiRouteParameters,
  SupplierPaymentCategoryApiRoutes,
} from "@/modules/supplier/constants/api-routes";
import { ApiResponse } from "@/core/http-client/http-client.types";
import {
  SupplierPaymentCategory,
  SupplierPaymentCategoryMandatory,
} from "../types/supplier-payment-category.type";

class SupplierPaymentCategoryApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  async getAll(userIds: number[]): Promise<SupplierPaymentCategory[]> {
    try {
      const response: ApiResponse<SupplierPaymentCategory[]> = await this.httpClient.get(
        SupplierPaymentCategoryApiRoutes.getAll,
        {
          params: {
            user_ids: userIds,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async update(
    id: number,
    payload: SupplierPaymentCategoryMandatory
  ): Promise<SupplierPaymentCategory> {
    try {
      const response: ApiResponse<SupplierPaymentCategory> = await this.httpClient.put(
        SupplierPaymentCategoryApiRoutes.updateSupplierPaymentCategory.replace(
          SupplierPaymentCategoryApiRouteParameters.id,
          id.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: SupplierPaymentCategoryMandatory): Promise<SupplierPaymentCategory> {
    try {
      const response: ApiResponse<SupplierPaymentCategory> = await this.httpClient.post(
        SupplierPaymentCategoryApiRoutes.createSupplierPaymentCategory,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      const response = await this.httpClient.delete(
        SupplierPaymentCategoryApiRoutes.deleteSupplierPaymentCategory.replace(
          SupplierPaymentCategoryApiRouteParameters.id,
          id.toString()
        )
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new SupplierPaymentCategoryApi();
