import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"cyan darken-2","small":""}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.onSelectClicked()}}},[_c(VListItemTitle,[_vm._v("\n        "+_vm._s(_vm.$t("select_button"))+"\n      ")]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-checkbox-outline")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.onDownloadClicked()}}},[_c(VListItemTitle,[_vm._v("\n        "+_vm._s(_vm.$t("download_button"))+"\n      ")]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-download")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.onRenameClicked()}}},[_c(VListItemTitle,[_vm._v("\n        "+_vm._s(_vm.$t("rename_button"))+"\n      ")]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.onMoveClicked()}}},[_c(VListItemTitle,[_vm._v("\n        "+_vm._s(_vm.$t("move_button"))+"\n      ")]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-folder-move-outline")])],1)],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.onDeleteClicked()}}},[_c(VListItemTitle,[_vm._v("\n        "+_vm._s(_vm.$t("delete_button"))+"\n      ")]),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }