import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c(VListItemIcon,{class:[_vm.isRtl ? 'ml-3' : 'mr-3', 'mt-3 mb-3']},[(_vm.file.type === 'file')?_c(VIcon,[_vm._v("mdi-file-outline")]):_vm._e(),(_vm.file.type === 'photo')?_c(VIcon,[_vm._v("mdi-file-image")]):_vm._e(),(_vm.file.type === 'document')?_c(VIcon,[_vm._v("mdi-file-document-outline")]):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.file.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.relativeDate(_vm.file.created_at)))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }