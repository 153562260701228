
























































































































































import { RichAppointment } from "@/modules/appointment/types/appointment.type";
import { Component, Prop } from "vue-property-decorator";
import { age, date, time } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import { Getter } from "vuex-class";

@Component({
  filters: {
    age, date, time,
  }
})
export default class CalendarEvent extends mixins(SmileMixin) {
  @Getter("auth/clinicName") clinicName: string;
  @Prop({ required: true }) event: { appointment: RichAppointment };
  @Prop({ required: true }) displayUserIds: number[];
  @Getter("auth/calendarViewColorType") calendarViewColorType: "patient-status" | "appointment-status" | "appointment-type";

  public isTooltipHover = false;
  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public onCalendarEventClicked() {
    if (!this.event.appointment) {
      return;
    }

    this.$emit("calendarEventClicked", this.event);
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  get isBirthdayWeek() {
    if (!this.event.appointment.patient || !this.event.appointment.patient.birth_date) {
      return false;
    }

    return this.daysDiffToBirthday(this.event.appointment.patient.birth_date) <= 7;
  }

  get isPhoneAppointment() {
    if (this.clinicName !== "מרפאת אליצור") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("טלפוני");
  }

  get isFoamTestAppointment() {
    if (this.clinicName !== "מרפאת אליצור") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("קצף");
  }

  get isTreatmentAppointment() {
    if (this.clinicName !== "ד״ר יהודית טיצ׳ר") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("טיפול");
  }


  get isCheckAppointment() {
    if (this.clinicName !== "ד״ר יהודית טיצ׳ר") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("ביקורת");
  }

  get isCleanAppointment() {
    if (this.clinicName !== "ד״ר יהודית טיצ׳ר") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("ניקוי");
  }

  get isFirstCheckAppointment() {
    if (this.clinicName !== "ד״ר יהודית טיצ׳ר") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("בדיקה ראשונה");
  }

  get isFirstAidAppointment() {
    if (this.clinicName !== "ד״ר יהודית טיצ׳ר") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("עזרה ראשונה");
  }

  get isNoPaymentAppointment() {
    if (this.clinicName !== "מרפאת קידו") {
      return false;
    }

    return this.event.appointment && this.event.appointment.notes && this.event.appointment.notes.includes("ללא תשלום");
  }
}
