






































































import { Component, Prop, Watch } from "vue-property-decorator";
import { ALL_TEETH, TEETH_SURFACES } from "@/modules/treatment/constants/denture-map";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Getter } from "vuex-class";
import { PlanTreatment } from "@/modules/treatment/types/plan-treatment.type";
import { PriceList, Treatment } from "@/modules/treatment/types/treatment.type";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import PlanTreatmentApi from "../../api/plan-treatment.api";
import DentalDirectSurface from "../form-inputs/DentalDirectSurface.vue";

@Component({
  components: {
    SentenceMenu,
    DentalDirectSurface
  },
})

export default class PlanTreatmentFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public planTreatment: PlanTreatment;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isShowingTreatmentCode") isShowingTreatmentCode: boolean;
  @Getter("auth/treatmentAttributesType") treatmentAttributesType: string;
  @Getter("auth/priceLists") priceLists: PriceList[];
  @Getter("auth/treatmentShowQuantity") treatmentShowQuantity: boolean;

  public priceListId = 1; // TODO default pricelist
  public missingTreatmentError = false;
  public invalidPriceError = false;
  public invalidDiscountError = false;
  public formLoading = false;
  public deleteLoading = false;
  public isActionsLoading = false;
  public treatmentId: number = null;
  public showDiscount = false;
  public price = 0;
  public inputQuantityKey = 0;
  public quantity: number = null;
  public totalPrice = 0;
  public discountPercent = 0;
  public teeth: number[] = [];
  public surface: string[] = [];
  public notes = "";

  @Watch("quantity")
  public onQuantityChanged(newQuantity: string, oldQuantity: number) {
    if (!oldQuantity || !parseInt(oldQuantity.toString())) {
      return;
    }
    if (!parseInt(newQuantity)) {
      this.quantity = oldQuantity;
      this.inputQuantityKey++;
      return;
    }
    const pricePerOne = this.price / oldQuantity;
    const totalPricePerOne = this.totalPrice / oldQuantity;
    this.price = pricePerOne * this.quantity;
    this.totalPrice = totalPricePerOne * this.quantity;
  }

  public appendSentence(sentence) {
    if (!this.notes) {
      this.notes = sentence;
      return;
    }

    this.notes += " " + sentence;
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }

  private fixIfTreatmentDeleted(id: number) {
    const selectedTreatment = this.$store.getters["auth/treatments"]
      .find(treatment => (treatment.id === id));

    if (selectedTreatment.deleted_at === null) {
      return;
    }

    this.notes = selectedTreatment.name + "\n" + this.notes;
    this.treatmentId = null;

    return selectedTreatment.deleted_at !== null;
  }

  mounted() {
    this.price = this.planTreatment.price;
    this.quantity = this.planTreatment.quantity;
    if (this.planTreatment.price === 0 && this.planTreatment.discount) {
      this.totalPrice = 0;
      this.discountPercent = 100;
    } else {
      this.totalPrice = Number((this.planTreatment.price - this.planTreatment.discount).toFixed(2));
      this.discountPercent = Math.ceil(this.planTreatment.discount / this.planTreatment.price * 100) || 0;
    }

    this.showDiscount = this.planTreatment.discount != 0;
    this.notes = this.planTreatment.notes;
    this.treatmentId = this.planTreatment.treatment_id;
    this.teeth = this.planTreatment.attributes.teeth ? this.planTreatment.attributes.teeth.toString().split(",").map(teeth => parseInt(teeth)) : [];
    this.surface = this.planTreatment.attributes.surface ? this.planTreatment.attributes.surface.split(",") : [];
    this.fixIfTreatmentDeleted(this.planTreatment.treatment_id);
  }

  get showPriceList() {
    if (!this.treatmentId) {
      return false;
    }

    const selectedTreatment = (this.$store.getters["auth/treatments"] as Treatment[])
      .find(({ id }) => (id === this.treatmentId));

    if (!selectedTreatment) {
      return false;
    }

    return !!(selectedTreatment.attributes && selectedTreatment.attributes.priceList);
  }

  public closeDialog() {
    this.$emit("close");
  }

  public setSurfaceValue(selectedSurface: string[]) {
    this.surface = selectedSurface;
  }

  public async deletePlanTreatment() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.deleteLoading = true;
    try {
      await PlanTreatmentApi.delete(this.planTreatment.id);
      this.$emit("planTreatmentDeleted", this.planTreatment.id);
    } catch (err) {
      this.$toastr.e(this.$t("saving_error"));
    } finally {
      this.deleteLoading = false;
    }
  }

  public async submitUpdateForm() {
    if (!this.treatmentId) {
      this.missingTreatmentError = true;
      return;
    }

    if (this.invalidDiscountError || this.invalidPriceError) {
      return;
    }

    const attributes = {
      priceListId: this.priceListId,
      teeth: this.teeth.length ? this.teeth.join(",") : undefined,
      surface: this.surface ? this.surface.join(",") : undefined
    };

    this.formLoading = true;
    const newTreatmentDiscount = Math.floor(parseInt(this.price.toString()) * 0.01 * parseFloat(this.discountPercent.toString())) || 0;
    const attempt = PlanTreatmentApi.update(this.planTreatment.id, this.treatmentId, attributes, this.price, newTreatmentDiscount, this.notes, this.quantity);

    attempt
      .then(patientTreatment => {
        this.$emit("treatmentUpdated", patientTreatment);
        this.closeDialog();
      })
      .catch(() => {
        this.formLoading = false;
        this.$toastr.e(this.$t("saving_error"));
      });
  }

  get surfaceOptions() {
    return TEETH_SURFACES.map(surface => ({
      value: surface,
      text: surface
    })
    );
  }

  get teethOptions() {
    return ALL_TEETH
      .map(teeth => ({
        value: teeth,
        text: teeth
      })
      );
  }

  get treatmentOptions() {
    return this.$store.getters["auth/treatments"]
      .filter(treatment => !treatment.deleted_at)
      .map(treatment => ({
        "text": treatment.name + (this.isShowingTreatmentCode ? (" (" + treatment.code + ")") : ""),
        "value": treatment.id
      }));
  }

  public treatmentSelected() {
    this.missingTreatmentError = false;
    this.invalidPriceError = false;
    const selectedTreatment = (this.$store.getters["auth/treatments"] as Treatment[])
      .find(treatment => (treatment.id === this.treatmentId));

    if (!selectedTreatment) {
      return;
    }
    const userId = this.$store.getters["auth/userId"];
    if (selectedTreatment.attributes && selectedTreatment.attributes.userPrice && selectedTreatment.attributes.userPrice[userId]) {
      this.price = selectedTreatment.attributes.userPrice[userId] * this.quantity;
    } else if (selectedTreatment.attributes && selectedTreatment.attributes.priceList && this.priceListId in selectedTreatment.attributes.priceList) {
      this.price = selectedTreatment.attributes.priceList[this.priceListId] * this.quantity;
    } else {
      this.price = selectedTreatment.price * this.quantity;
    }

    if (!this.discountPercent) {
      this.totalPrice = this.price;
    }

    if (!this.notes) {
      this.notes = selectedTreatment.default_notes;
    }

    if (this.showDiscount) {
      this.onDiscountPercentChanged(this.discountPercent.toString());
    }
  }

  public onPriceChanged(price: string) {
    this.invalidPriceError = (!parseFloat(price) && parseFloat(price) !== 0);
    if (!parseFloat(price)) {
      this.totalPrice = 0;
      return;
    }

    const discount = Math.floor(parseInt(price.toString()) * 0.01 * parseFloat(this.discountPercent.toString()));
    if (this.invalidDiscountError) {
      this.totalPrice = 0;
    } else if (discount) {
      this.totalPrice = Number((parseFloat(price) - discount).toFixed(2));
    } else {
      this.totalPrice = parseFloat(price.toString());
    }
  }

  public onDiscountPercentChanged(discountPercent: string) {
    this.invalidDiscountError = parseFloat(discountPercent) < 0 || parseFloat(discountPercent) > 100;
    const discount = Math.floor(parseInt(this.price.toString()) * 0.01 * parseFloat(discountPercent));
    if (this.invalidDiscountError) {
      this.totalPrice = 0;
    } else if (discount) {
      this.totalPrice = Number((this.price - discount).toFixed(2));
    } else {
      this.totalPrice = parseFloat(this.price.toString());
    }
  }

}
