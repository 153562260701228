



















































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Plan } from "@/modules/treatment/types/plan.type";
import { date, age } from "@/filters/date.filter";
import { DENTURES_MAP, TEETH_SURFACES } from "@/modules/treatment/constants/denture-map";
import PlanDenture from "./PlanDenture.vue";
import PlanApi from "@/modules/treatment/api/plan.api";
import PlanTreatmentApi from "@/modules/treatment/api/plan-treatment.api";
import { Getter } from "vuex-class";
import { PlanTreatment } from "@/modules/treatment/types/plan-treatment.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { Patient } from "@/modules/patient/types/patient.type";
import moment from "moment";
import SentenceMenu from "@/modules/appointment/components/appointment-documentation/SentenceMenu.vue";
import EditPlanDialog from "./plan/EditPlanDialog.vue";
import DiscountPlanDialog from "./plan/DiscountPlanDialog.vue";
import PatientTreatmentFormDialog from "@/modules/patient/components/PatientTreatmentFormDialog.vue";
import { PatientTreatment } from "@/modules/patient/types/patient-treatment.type";
import { Treatment } from "../types/treatment.type";
import SharePlanEmailDialog from "./plan/SharePlanEmailDialog.vue";
import SharePlanWhatsappDialog from "./plan/SharePlanWhatsappDialog.vue";
import PlanTreatmentFormDialog from "./plan/PlanTreatmentFormDialog.vue";
import { Form } from "@/modules/form/types/form.type";
import CreateFormDialog from "@/modules/form/components/CreateFormDialog.vue";
import DentalDirectSurface from "./form-inputs/DentalDirectSurface.vue";

@Component({
  components: {
    PlanTreatmentFormDialog,
    SentenceMenu,
    PlanDenture,
    EditPlanDialog,
    SharePlanEmailDialog,
    PatientTreatmentFormDialog,
    SharePlanWhatsappDialog,
    DiscountPlanDialog,
    CreateFormDialog,
    DentalDirectSurface,
  },
  filters: {
    date,
    age,
  }
})

export default class PlanDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public plan: Plan;
  @Prop({ required: false }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isPlanTreatmentStatusWorkingOnIt") isPlanTreatmentStatusWorkingOnIt: boolean;
  @Getter("auth/isEmailEnabled") isEmailEnabled: boolean;
  @Getter("auth/isShowingTreatmentCode") isShowingTreatmentCode: boolean;
  @Getter("auth/treatmentAttributesType") treatmentAttributesType: string;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  @Getter("auth/planFormId") planFormId: number;
  @Getter("auth/treatmentShowQuantity") treatmentShowQuantity: boolean;

  public showMobileDenture = false;
  public denture = "";
  public notes = "";
  public missingTreatmentError = false;
  public invalidDiscountError = false;
  public invalidPriceError = false;
  public showDiscount = false;
  public newTreatmentPrice = 0;
  public newTreatmentId = 0;
  public newTreatmentDiscountPercent = "0";
  public newTreatmentTotalPrice = "0";
  public newTreatmentNotes = "";
  public newTreatmentColor = "";
  public inputQuantityKey = 0;
  public quantity = 1;
  public newTreatmentSurface: string[] = [];
  public newTreatmentTeeth: number[] = [];
  public newTreatmentLoadingCount = 0;
  public selectedPlanTreatmentIds: number[] = [];
  public isLoadingTreatmentAction = false;
  public isLoadingTreatmentDelete = false;
  public isPlanActionsLoading = false;
  public showEditDialog = false;
  public showDiscountDialog = false;
  public isLoadingMarkAllDone = false;
  public showShareEmailDialog = false;
  public showShareWhatpsappDialog = false;
  public displayTreatmentFormDialog = false;
  public displayPlanTreatmentFormDialog = false;
  public editingTreatment: PlanTreatment = null;
  public newTreatmentWorking = false;
  public showCreateFormDialog = false;

  public async onMarkTreatmentDoneClicked(treatment: PlanTreatment) {
    this.clearSelectedTreatents();
    if (treatment.started_at) {
      const updatedPlanTreatment = await PlanTreatmentApi.updateStatus(treatment.id, true);
      this.$set(treatment, "done_at", updatedPlanTreatment.done_at);
    } else {
      this.newTreatmentWorking = false;
      this.displayTreatmentFormDialog = true;
      this.editingTreatment = treatment;
    }
  }

  @Watch("quantity")
  public onQuantityChanged(newQuantity: string, oldQuantity: number) {
    if (!oldQuantity || !parseInt(oldQuantity.toString())) {
      return;
    }
    if (!parseInt(newQuantity)) {
      this.quantity = oldQuantity;
      this.inputQuantityKey++;
      return;
    }
    const pricePerOne = this.newTreatmentPrice / oldQuantity;
    const totalPricePerOne = parseInt(this.newTreatmentTotalPrice) / oldQuantity;
    this.newTreatmentPrice = pricePerOne * this.quantity;
    this.newTreatmentTotalPrice = (totalPricePerOne * this.quantity).toString();
  }

  get planForm() {
    return (this.$store.getters["auth/forms"] as Form[])
      .find(({ id }) => (id === this.planFormId));
  }

  public onCreateFormClicked() {
    this.showCreateFormDialog = true;
  }

  public onCloseFormClicked() {
    this.showCreateFormDialog = false;
  }

  public setSurfaceValue(selectedSurface: string[]) {
    this.newTreatmentSurface = selectedSurface;
  }

  public async onMarkTreatmentWorkingClicked(treatment: PlanTreatment) {
    this.clearSelectedTreatents();
    this.newTreatmentWorking = true;
    this.displayTreatmentFormDialog = true;
    this.editingTreatment = treatment;
  }

  public async onTreatmentCreated(patientTreatment: PatientTreatment) {
    const treatment = this.newTreatmentWorking
      ? await PlanTreatmentApi.updateStartedAt(this.editingTreatment.id, true)
      : await PlanTreatmentApi.updateStatus(this.editingTreatment.id, true);

    this.$toastr.s(this.$t("treatment_created_toastr"));
    this.patient.treatments.unshift(patientTreatment);
    const planTreatment = this.plan.plan_treatments.find(t => (t.id === this.editingTreatment.id));
    this.$set(planTreatment, "done_at", treatment.done_at);
    this.$set(planTreatment, "started_at", treatment.started_at);

  }

  public async onPlanTreatmentDeleted(planTreatmentId: number) {
    this.plan.plan_treatments.splice(
      this.plan.plan_treatments.map(treatment => (treatment.id)).indexOf(planTreatmentId)
      , 1);
    this.onEditPlanTreatmentClosed();
    this.$toastr.s(this.$t("plan_updated_toastr"));
  }

  public async onPlanTreatmentUpdated(planTreatment: PlanTreatment) {
    const planTreatmentIndex = this.plan.plan_treatments.map(treatment => (treatment.id)).indexOf(planTreatment.id);
    this.plan.plan_treatments[planTreatmentIndex].discount = planTreatment.discount;
    this.plan.plan_treatments[planTreatmentIndex].quantity = planTreatment.quantity;
    this.plan.plan_treatments[planTreatmentIndex].price = planTreatment.price;
    this.plan.plan_treatments[planTreatmentIndex].treatment_id = planTreatment.treatment_id;
    this.plan.plan_treatments[planTreatmentIndex].notes = planTreatment.notes;
    this.plan.plan_treatments[planTreatmentIndex].attributes = planTreatment.attributes;
  }

  public onEditPlanTreatmentClicked(planTreatment: PlanTreatment) {
    this.editingTreatment = planTreatment;
    this.displayPlanTreatmentFormDialog = true;
  }

  public onEditPlanTreatmentClosed() {
    this.editingTreatment = null;
    this.displayPlanTreatmentFormDialog = false;
  }

  get todoTreatments() {
    return this.plan.plan_treatments.filter(({ done_at }) => (!done_at));
  }

  mounted() {
    this.denture = this.plan.attributes && this.plan.attributes.denture
      ? this.plan.attributes.denture
      : null;

    this.notes = this.plan.notes;
  }

  public async onMarkPlanNotCompletedClicked() {
    try {
      this.isPlanActionsLoading = true;
      await PlanApi.update(this.plan.id, this.plan.name, this.plan.attributes, false, this.notes, this.plan.user_id, this.plan.created_at);
      this.plan.completed_at = null;
      this.$toastr.s(this.$t("plan_not_completed_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    } finally {
      this.isPlanActionsLoading = false;
    }
  }

  public async onNotesChanged() {
    try {
      await PlanApi.update(this.plan.id, this.plan.name, this.plan.attributes, !!this.plan.completed_at, this.notes, this.plan.user_id, this.plan.created_at);
      this.plan.notes = this.notes;
      this.$toastr.s(this.$t("notes_updated_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public treatmentsFilter(item: any, queryText: string) {
    return `${item.text} ${item.code}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }

  public async onDownloadClicked() {
    this.isPlanActionsLoading = true;

    try {
      const url = await PlanApi.getUrl(this.plan.id);
      this.isPlanActionsLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, `${this.plan.name}.pdf`);
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isPlanActionsLoading = false;
    }
  }

  public appendSentence(sentence) {
    if (!this.newTreatmentNotes) {
      this.newTreatmentNotes = sentence;
      return;
    }

    this.newTreatmentNotes += " " + sentence;
  }

  public async onShareWhatsappWebClicked() {
    this.isPlanActionsLoading = true;
    try {
      const url = await PlanApi.getUrl(this.plan.id);
      this.isPlanActionsLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("whatsapp_share_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }
      this.shareWatsappWebMessage(this.patient.phone, url);
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isPlanActionsLoading = false;
    }

  }

  public async onMarkAllTreatmentsDoneClicked() {
    const res = await this.$confirm(this.$t("mark_all_done_confirm_message", { phone: this.patient.phone }).toString(), {
      buttonTrueColor: "cyan darken-2",
      buttonTrueText: this.$t("mark_all_done_ok_btn").toString(),
      buttonFalseText: this.$t("mark_all_done_cancel_btn").toString()
    });
    if (!res) {
      return;
    }

    this.isLoadingMarkAllDone = true;
    const patientTreatments = await PlanApi.markAllTreatmentsDone(this.plan.id);
    patientTreatments.forEach(patientTreatment => {
      this.patient.treatments.unshift(patientTreatment);
    });

    this.todoTreatments.forEach((planTreatment) => {
      this.$set(planTreatment, "done_at", moment().format("YYYY-MM-DD HH:mm:ss"));
      planTreatment;
    });
    this.isLoadingMarkAllDone = false;
    await this.onMarkPlanCompletedClicked();
  }

  public async onMarkPlanCompletedClicked() {
    try {
      this.isPlanActionsLoading = true;
      await PlanApi.update(this.plan.id, this.plan.name, this.plan.attributes, true, this.notes, this.plan.user_id, this.plan.created_at);
      this.plan.completed_at = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$toastr.s(this.$t("plan_completed_toastr"));
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    } finally {
      this.isPlanActionsLoading = false;
    }
  }

  public async onPlanUpdateDiscount(discountPercent: number) {
    const promises = [];

    this.isLoadingTreatmentDelete = true;
    this.plan.plan_treatments.forEach(planTreatment => {
      if (planTreatment.done_at || planTreatment.started_at) {
        return;
      }
      const discount = Math.floor(planTreatment.price * discountPercent / 100);
      promises.push(
        PlanTreatmentApi.update(planTreatment.id, planTreatment.treatment_id, planTreatment.attributes, planTreatment.price, discount, planTreatment.notes, planTreatment.quantity)
      );
    });

    Promise.allSettled(promises)
      .then(() => {
        for (let i = 0; i < this.plan.plan_treatments.length; i++) {
          if (this.plan.plan_treatments[i].done_at) {
            continue;
          }
          const discount = Math.floor(this.plan.plan_treatments[i].price * discountPercent / 100);
          this.plan.plan_treatments[i].discount = discount;
        }

        this.$toastr.s(this.$t("plan_updated_toastr"));
      })
      .catch(() => {
        this.$toastr.e(this.$t("temporary_error"));
      });
  }

  public async onPlanEdited(name: string, userId: number, createdAt: string) {
    try {
      this.isPlanActionsLoading = true;
      await PlanApi.update(this.plan.id, name, this.plan.attributes, !!this.plan.completed_at, this.notes, userId, createdAt);
      this.plan.name = name;
      this.plan.user_id = userId;
      this.plan.created_at = createdAt;
      this.$toastr.s(this.$t("plan_updated_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    } finally {
      this.isPlanActionsLoading = false;
    }
  }

  public async closeDialog() {
    if (this.plan.plan_treatments.length === 0 && !this.plan.notes) {
      try {
        const plan = await PlanApi.getOne(this.plan.id);
        if (plan.plan_treatments.length === 0 && !plan.notes) {
          await PlanApi.delete(this.plan.id);
          this.$emit("planDeleted", this.plan.id);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
    this.$emit("close");
  }

  get totalPrice() {
    if (this.plan.plan_treatments.length === 0)
      return 0;

    return this.plan.plan_treatments
      .map(treatment => (treatment.price - treatment.discount))
      .reduce((a, b) => (a + b));
  }

  get dentureOptions() {
    return DENTURES_MAP.map(denture => {
      return {
        value: denture.name,
        text: this.$t("dentures." + denture.name)
      };
    });
  }

  get surfaceOptions() {
    return TEETH_SURFACES.map(surface => ({
      value: surface,
      text: surface
    })
    );
  }

  get teethOptions() {
    const dentureConfig = DENTURES_MAP
      .filter(denture => (denture.name === this.denture));

    if (dentureConfig.length === 0)
      return [];

    return dentureConfig[0]
      .teeth
      .map(teeth => ({
        value: teeth,
        text: teeth
      })
      );
  }

  get treatmentOptions() {
    return this.$store.getters["auth/treatments"]
      .filter(treatment => !treatment.deleted_at)
      .map(treatment => ({
        "text": treatment.name,
        "code": treatment.code,
        "value": treatment.id
      }));
  }

  public onShareEmailClicked() {
    this.showShareEmailDialog = true;
  }

  public onShareWhatsappClicked() {
    this.showShareWhatpsappDialog = true;
  }

  public async deleteBtnClicked() {
    const res = await this.$confirm(this.$t("delete_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    });
    if (!res) {
      return;
    }

    this.isPlanActionsLoading = true;
    try {
      await PlanApi.delete(this.plan.id);
      this.$toastr.s(this.$t("deleted_toastr"));
      this.$emit("planDeleted", this.plan.id);
      this.closeDialog();
    } catch (err) {
      this.$toastr.e(this.$t("error_deleting_plan"));
    } finally {
      this.isPlanActionsLoading = false;
    }
  }

  public getRowClass(treatment: PlanTreatment) {
    if (this.selectedPlanTreatmentIds.includes(treatment.id)) {
      return "cyan lighten-5";
    }

    if (!treatment.done_at && !treatment.started_at) {
      return "todo-row";
    }

    if (!treatment.done_at && treatment.started_at) {
      return "started-row";
    }

    return "";
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public onPriceChanged(price: string) {
    this.invalidPriceError = (!parseFloat(price) && parseFloat(price) !== 0) || parseFloat(price) < 0;
    if (!parseFloat(price) || parseFloat(price) < 0) {
      this.newTreatmentTotalPrice = "0";
      return;
    }

    const discount = Math.floor(parseInt(price.toString()) * 0.01 * parseFloat(this.newTreatmentDiscountPercent));
    if (this.invalidDiscountError) {
      this.newTreatmentTotalPrice = "0";
    } else if (discount) {
      this.newTreatmentTotalPrice = (parseFloat(price) - discount).toString();
    } else {
      this.newTreatmentTotalPrice = parseFloat(price.toString()).toString();
    }
  }

  public onDiscountPercentChanged(discountPercent: string) {
    this.invalidDiscountError = parseFloat(discountPercent) < 0 || parseFloat(discountPercent) > 100;
    const discount = Math.floor(parseInt(this.newTreatmentPrice.toString()) * 0.01 * parseFloat(discountPercent));
    if (this.invalidDiscountError) {
      this.newTreatmentTotalPrice = "0";
    } else if (discount) {
      this.newTreatmentTotalPrice = (this.newTreatmentPrice - discount).toString();
    } else {
      this.newTreatmentTotalPrice = parseFloat(this.newTreatmentPrice.toString()).toString();
    }
  }

  public treatmentSelectedChange(treatment: PlanTreatment) {
    if (this.selectedPlanTreatmentIds.indexOf(treatment.id) === -1) {
      this.clearSelectedTreatents();
      this.selectedPlanTreatmentIds.push(treatment.id);
    } else {
      this.selectedPlanTreatmentIds.splice(
        this.selectedPlanTreatmentIds.indexOf(treatment.id), 1);
    }
  }

  public clearSelectedTreatents() {
    this.selectedPlanTreatmentIds.splice(0, this.selectedPlanTreatmentIds.length);
  }

  public deleteSelectedTreatments(planTreatmentId: number) {
    this.clearSelectedTreatents();
    this.$confirm(this.$t("delete_treatments_confirm_message").toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString()
    })
      .then(res => {
        if (res) {
          const promises = [];

          this.isLoadingTreatmentDelete = true;
          [planTreatmentId].forEach(id => {
            promises.push(
              PlanTreatmentApi.delete(id)
            );
          });

          Promise.allSettled(promises)
            .then(() => {
              [planTreatmentId].forEach(id => {
                this.plan.plan_treatments.splice(
                  this.plan.plan_treatments.map(treatment => (treatment.id)).indexOf(id)
                  , 1);
              });

              this.isLoadingTreatmentDelete = false;
              this.selectedPlanTreatmentIds = [];
              this.$toastr.s(this.$t("plan_updated_toastr"));
            })
            .catch(() => {
              this.isLoadingTreatmentDelete = false;
              this.$toastr.e(this.$t("temporary_error"));
            });
        }
      });
  }

  public newTreatmentSelected($event) {
    this.missingTreatmentError = false;
    this.invalidPriceError = false;
    const selectedTreatment = this.$store.getters["auth/treatments"]
      .find(treatment => (treatment.id === $event));

    if (!selectedTreatment) {
      return;
    }
    const userId = this.$store.getters["auth/userId"];
    if (selectedTreatment.attributes && selectedTreatment.attributes.userPrice && selectedTreatment.attributes.userPrice[userId]) {
      this.newTreatmentPrice = selectedTreatment.attributes.userPrice[userId] * this.quantity;
    } else {
      this.newTreatmentPrice = selectedTreatment.price * this.quantity;
    }

    if (!parseInt(this.newTreatmentDiscountPercent)) {
      this.newTreatmentTotalPrice = this.newTreatmentPrice.toString();
    }
    if (!this.newTreatmentNotes) {
      this.newTreatmentNotes = selectedTreatment.default_notes;
    }

    if (!this.newTreatmentColor) {
      this.newTreatmentColor = selectedTreatment.color;
    }
  }

  public async addTreatment() {
    if (!this.newTreatmentId) {
      this.missingTreatmentError = true;
      return;
    }

    if (this.invalidPriceError) {
      return;
    }

    if (parseInt(this.newTreatmentDiscountPercent) < 0 || parseInt(this.newTreatmentDiscountPercent) > 100) {
      this.invalidDiscountError = true;
      return;
    }

    if (this.isTreatmentDuplicatePerTooth) {
      (this.newTreatmentTeeth.length ? this.newTreatmentTeeth : [undefined]).forEach(teeth => {

        const attributes = {
          teeth,
          surface: this.newTreatmentSurface ? this.newTreatmentSurface.join(",") : undefined
        };

        this.newTreatmentLoadingCount++;
        const newTreatmentDiscount = Math.floor(parseInt(this.newTreatmentPrice.toString()) * 0.01 * parseFloat(this.newTreatmentDiscountPercent));
        PlanTreatmentApi.create(this.plan.id, this.newTreatmentId, attributes, this.newTreatmentPrice, newTreatmentDiscount, this.newTreatmentNotes, this.newTreatmentColor, this.quantity)
          .then(planTreatment => {

            this.plan.plan_treatments.unshift(planTreatment);
            this.$toastr.s(this.$t("plan_treatment_created"));

            this.newTreatmentSurface = [];
            this.newTreatmentTeeth = [];
            this.newTreatmentDiscountPercent = "0";
            this.newTreatmentTotalPrice = "0";
            this.newTreatmentPrice = 0;
            this.newTreatmentNotes = "";
            this.newTreatmentColor = "";
            this.newTreatmentId = 0;
            this.showDiscount = false;
            this.newTreatmentLoadingCount--;
          }).catch(() => {
            this.$toastr.e(this.$t("temporary_error"));
            this.newTreatmentLoadingCount--;
          });
      });
    } else {
      const attributes = {
        teeth: this.newTreatmentTeeth.length ? this.newTreatmentTeeth.join(",") : undefined,
        surface: this.newTreatmentSurface ? this.newTreatmentSurface.join(",") : undefined
      };

      this.newTreatmentLoadingCount++;
      const newTreatmentDiscount = Math.floor(parseInt(this.newTreatmentPrice.toString()) * 0.01 * parseFloat(this.newTreatmentDiscountPercent));
      PlanTreatmentApi.create(this.plan.id, this.newTreatmentId, attributes, this.newTreatmentPrice, newTreatmentDiscount, this.newTreatmentNotes, this.newTreatmentColor, this.quantity)
        .then(planTreatment => {

          this.plan.plan_treatments.unshift(planTreatment);
          this.$toastr.s(this.$t("plan_treatment_created"));

          this.newTreatmentSurface = [];
          this.newTreatmentTeeth = [];
          this.newTreatmentDiscountPercent = "0";
          this.newTreatmentTotalPrice = "0";
          this.newTreatmentPrice = 0;
          this.newTreatmentNotes = "";
          this.newTreatmentColor = "";
          this.newTreatmentId = 0;
          this.showDiscount = false;
          this.newTreatmentLoadingCount--;
        }).catch(() => {
          this.$toastr.e(this.$t("temporary_error"));
          this.newTreatmentLoadingCount--;
        });
    }
  }

  get isTreatmentDuplicatePerTooth() {
    if (!this.newTreatmentId) {
      return false;
    }

    const treatment = (this.$store.getters["auth/treatments"] as Treatment[]).find(({ id }) => (id === this.newTreatmentId));
    return treatment.attributes && treatment.attributes.isDuplicatePerTooth;
  }

  public async onAddInlineTreatment(treatmentId: number, attributes: any) {
    this.clearSelectedTreatents();
    const userId = this.$store.getters["auth/userId"];
    const selectedTreatment = (this.$store.getters["auth/treatments"] as Treatment[])
      .find(treatment => (treatment.id === treatmentId));

    let treatmentPrice = selectedTreatment.price;
    if (selectedTreatment.attributes && selectedTreatment.attributes.userPrice && selectedTreatment.attributes.userPrice[userId]) {
      treatmentPrice = selectedTreatment.attributes.userPrice[userId];
    }

    try {
      const planTreatment = await PlanTreatmentApi.create(this.plan.id, treatmentId, attributes, treatmentPrice, 0, selectedTreatment.default_notes, selectedTreatment.color, 1);
      this.plan.plan_treatments.unshift(planTreatment);
      this.$toastr.s(this.$t("plan_treatment_created"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public treatmentSelected(id: number) {
    const selectedTreatment = this.$store.getters["auth/treatments"]
      .find(treatment => (treatment.id === id));

    return selectedTreatment.code;
  }

  get enableDoneButton() {
    for (let i = 0; i < this.plan.plan_treatments.length; i++) {
      if (this.selectedPlanTreatmentIds.includes(this.plan.plan_treatments[i].id) && this.plan.plan_treatments[i].done_at) {
        return false;
      }
    }
    return true;
  }

  get enableTodoButton() {
    for (let i = 0; i < this.plan.plan_treatments.length; i++) {
      if (this.selectedPlanTreatmentIds.includes(this.plan.plan_treatments[i].id) && !this.plan.plan_treatments[i].done_at) {
        return false;
      }
    }
    return true;
  }

  public async onDentureChanged() {
    try {
      this.plan.attributes.denture = this.denture;
      await PlanApi.update(this.plan.id, this.plan.name, this.plan.attributes, !!this.plan.completed_at, this.notes, this.plan.user_id, this.plan.created_at);
      this.plan.plan_treatments.forEach(planTreatment => {
        const dentureTeeth = DENTURES_MAP.find(({ name }) => (name === this.denture)).teeth;
        if (planTreatment.attributes.teeth && !dentureTeeth.includes(parseInt(planTreatment.attributes.teeth))) {
          if (parseInt(planTreatment.attributes.teeth) > 50) {
            PlanTreatmentApi.update(planTreatment.id, planTreatment.treatment_id, {
              ...planTreatment.attributes,
              teeth: (parseInt(planTreatment.attributes.teeth) - 40).toString()
            }, planTreatment.price, planTreatment.discount, planTreatment.notes, planTreatment.quantity)
              .then(() => {
                planTreatment.attributes.teeth = (parseInt(planTreatment.attributes.teeth) - 40).toString();
              });
          } else if (parseInt(planTreatment.attributes.teeth) % 10 <= 5) {
            PlanTreatmentApi.update(planTreatment.id, planTreatment.treatment_id, {
              ...planTreatment.attributes,
              teeth: (parseInt(planTreatment.attributes.teeth) + 40).toString()
            }, planTreatment.price, planTreatment.discount, planTreatment.notes, planTreatment.quantity)
              .then(() => {
                planTreatment.attributes.teeth = (parseInt(planTreatment.attributes.teeth) + 40).toString();
              });
          }
        }
      });
      this.$toastr.s(this.$t("denture_updated_toastr"));
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

}
