import {RouteConfig} from "vue-router";
import SettingsPage from "../pages/SettingsPage.vue";
import {APPOINTMENT_STATUS_PAGE_ROUTE_NAME, APPOINTMENT_STATUS_PAGE_ROUTE_PATH, APPOINTMENT_TYPE_PAGE_ROUTE_NAME, APPOINTMENT_TYPE_PAGE_ROUTE_PATH, CONTACT_BOOKMARKS_PAGE_ROUTE_NAME, CONTACT_BOOKMARKS_PAGE_ROUTE_PATH, DRUGS_PAGE_ROUTE_NAME, DRUGS_PAGE_ROUTE_PATH, IMAGINING_AGENT_PAGE_ROUTE_NAME, IMAGINING_AGENT_PAGE_ROUTE_PATH, LETTER_PAGE_ROUTE_NAME, LETTER_PAGE_ROUTE_PATH, PATIENT_STATUSES_PAGE_ROUTE_NAME, PATIENT_STATUSES_PAGE_ROUTE_PATH, PRICE_LISTS_PAGE_ROUTE_NAME, PRICE_LISTS_PAGE_ROUTE_PATH, QUICK_ACTION_AND_TEMPLATE_LETTER_PAGE_ROUTE_NAME, QUICK_ACTION_AND_TEMPLATE_LETTER_PAGE_ROUTE_PATH, QUICK_ACTION_PAGE_ROUTE_NAME, QUICK_ACTION_PAGE_ROUTE_PATH, SENTENCES_AND_CATAGORIES_PAGE_ROUTE_NAME, SENTENCES_AND_CATAGORIES_PAGE_ROUTE_PATH, SENTENCES_CATAGORIES_PAGE_ROUTE_NAME, SENTENCES_CATAGORIES_PAGE_ROUTE_PATH, SENTENCES_TEMPLATES_PAGE_ROUTE_NAME, SENTENCES_TEMPLATES_PAGE_ROUTE_PATH, SETTINGS_PAGE_ROUTE_NAME, SETTINGS_PAGE_ROUTE_PATH, SHIFTS_PAGE_ROUTE_NAME, SHIFTS_PAGE_ROUTE_PATH, SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME, SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_PATH, TAGS_PAGE_ROUTE_NAME, TAGS_PAGE_ROUTE_PATH, TIME_ENTRY_TYPES_PAGE_ROUTE_NAME, TIME_ENTRY_TYPES_PAGE_ROUTE_PATH, TREATMENTS_PAGE_ROUTE_NAME, TREATMENTS_PAGE_ROUTE_PATH} from "@/modules/settings/constants/route-config";
import ManageDrugsPage from "../pages/ManageDrugsPage.vue";
import ManageTagsPage from "../pages/ManageTagsPage.vue";
import ManageTreatmentsPage from "../pages/ManageTreatmentsPage.vue";
import ManagePriceListsPage from "../pages/ManagePriceListsPage.vue";
import ManagePatientStatusPage from "../pages/ManagePatientStatusesPage.vue";
import ManageTimeEntryTypesPage from "../pages/ManageTimeEntryTypesPage.vue";
import ManageShiftsAndRecurringsPage from "../pages/ManageShiftsAndRecurringsPage.vue";
import ManageContactBookmarksPage from "../pages/ManageContactBookmarksPage.vue";
import ManageQuickActionAndTemplateLetterPage from "../pages/ManageQuickActionAndTemplateLetterPage.vue";
import ManageSentencesAndCategoriesPage from "../pages/ManageSentencesAndCategoriesPage.vue";
import ManageAppointmentTypesPage from "../pages/ManageAppointmentTypesPage.vue";
import ManageImagingAgentsPage from "../pages/ManageImagingAgentsPage.vue";
import ManageSupplierPaymentCategoriesPage from "../pages/ManageSupplierPaymentCategoriesPage.vue";
import ManageAppointmentStatusesPage from "../pages/ManageAppointmentStatusesPage.vue";
import ManageQuickActionCategoriesPage from "../pages/ManageQuickActionCategoriesPage.vue";
import ManageTemplateLettersPage from "../pages/ManageTemplateLettersPage.vue";
import ManageSentenceCategoriesPage from "../pages/ManageSentenceCategoriesPage.vue";
import ManageSentencesPage from "../pages/ManageSentencesPage.vue";

const settingsRoutes: RouteConfig[] = [
    {
        name: SETTINGS_PAGE_ROUTE_NAME,
        path: SETTINGS_PAGE_ROUTE_PATH,
        component: SettingsPage,
    },
    {
        name: DRUGS_PAGE_ROUTE_NAME,
        path: DRUGS_PAGE_ROUTE_PATH,
        component: ManageDrugsPage,
    },
    {
        name: TAGS_PAGE_ROUTE_NAME,
        path: TAGS_PAGE_ROUTE_PATH,
        component: ManageTagsPage,
    },
    {
        name: TREATMENTS_PAGE_ROUTE_NAME,
        path: TREATMENTS_PAGE_ROUTE_PATH,
        component: ManageTreatmentsPage,
    },
    {
        name: PRICE_LISTS_PAGE_ROUTE_NAME,
        path: PRICE_LISTS_PAGE_ROUTE_PATH,
        component: ManagePriceListsPage,
    },
    {
        name: PATIENT_STATUSES_PAGE_ROUTE_NAME,
        path: PATIENT_STATUSES_PAGE_ROUTE_PATH,
        component: ManagePatientStatusPage,
    },
    {
        name: TIME_ENTRY_TYPES_PAGE_ROUTE_NAME,
        path: TIME_ENTRY_TYPES_PAGE_ROUTE_PATH,
        component: ManageTimeEntryTypesPage,
    },
    {
        name: SHIFTS_PAGE_ROUTE_NAME,
        path: SHIFTS_PAGE_ROUTE_PATH,
        component: ManageShiftsAndRecurringsPage,
    },
    {
        name: CONTACT_BOOKMARKS_PAGE_ROUTE_NAME,
        path: CONTACT_BOOKMARKS_PAGE_ROUTE_PATH,
        component: ManageContactBookmarksPage,
    },
    {
        name: QUICK_ACTION_AND_TEMPLATE_LETTER_PAGE_ROUTE_NAME,
        path: QUICK_ACTION_AND_TEMPLATE_LETTER_PAGE_ROUTE_PATH,
        component: ManageQuickActionAndTemplateLetterPage,
        children: [
            {
                name: QUICK_ACTION_PAGE_ROUTE_NAME,
                path: QUICK_ACTION_PAGE_ROUTE_PATH,
                component: ManageQuickActionCategoriesPage,
            },
            {
                name: LETTER_PAGE_ROUTE_NAME,
                path: LETTER_PAGE_ROUTE_PATH,
                component: ManageTemplateLettersPage,
            },
        ]

    },
    {
        name: SENTENCES_AND_CATAGORIES_PAGE_ROUTE_NAME,
        path: SENTENCES_AND_CATAGORIES_PAGE_ROUTE_PATH,
        component: ManageSentencesAndCategoriesPage,
        children: [
            {
                name: SENTENCES_CATAGORIES_PAGE_ROUTE_NAME,
                path: SENTENCES_CATAGORIES_PAGE_ROUTE_PATH,
                component: ManageSentenceCategoriesPage,
            },
            {
                name: SENTENCES_TEMPLATES_PAGE_ROUTE_NAME,
                path: SENTENCES_TEMPLATES_PAGE_ROUTE_PATH,
                component: ManageSentencesPage,
            },
        ]
    },
    {
        name: APPOINTMENT_TYPE_PAGE_ROUTE_NAME,
        path: APPOINTMENT_TYPE_PAGE_ROUTE_PATH,
        component: ManageAppointmentTypesPage,
    },
    {
        name: APPOINTMENT_STATUS_PAGE_ROUTE_NAME,
        path: APPOINTMENT_STATUS_PAGE_ROUTE_PATH,
        component: ManageAppointmentStatusesPage,
    },
    {
        name: IMAGINING_AGENT_PAGE_ROUTE_NAME,
        path: IMAGINING_AGENT_PAGE_ROUTE_PATH,
        component: ManageImagingAgentsPage,
    },
    {
        name: SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME,
        path: SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_PATH,
        component: ManageSupplierPaymentCategoriesPage,
    },

];

export default settingsRoutes;
