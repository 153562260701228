import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"560px","persistent":_vm.isDirty},on:{"input":_vm.closeDialog}},[_c(VCard,{staticClass:"patient-note-form-dialog",attrs:{"outlined":"","raised":"","elevation":"4"},on:{"change":function($event){return _vm.markDirty()}}},[_c(VCardTitle,{staticClass:"justify-space-between align-start"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.editingNote ? _vm.$t('edit_title') : _vm.$t('add_title'))+"\n      ")]),_c(VBtn,{class:_vm.isRtl ? 'float-left' : 'float-right',attrs:{"icon":"","color":"lighten-4"},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6","sm":"4"}},[_c(VTextField,{attrs:{"value":_vm.caregiverName,"dense":"","disabled":"","color":"cyan","label":_vm.$t('user_label')}})],1),_c(VCol,{attrs:{"cols":"6","sm":"3"}},[_c(VTextField,{attrs:{"value":_vm._f("date")(_vm.updatedAtDate),"disabled":"","dense":"","color":"cyan","label":_vm.$t('date_label')}})],1),_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VSelect,{attrs:{"items":_vm.pinnedOptions,"dense":"","color":"cyan","label":_vm.$t('pinned_label'),"item-color":"cyan darken-1"},on:{"change":function($event){return _vm.markDirty()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"100%"}},[_c(VIcon,{attrs:{"color":item.value ? 'orange lighten-4' : 'cyan lighten-4'}},[_vm._v("mdi-square")]),_c('span',{staticClass:"mr-2 ml-2"},[_vm._v(_vm._s(item.text))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"100%"}},[_c(VIcon,{attrs:{"color":item.value ? 'orange lighten-4' : 'cyan lighten-4'}},[_vm._v("mdi-square")]),_c('span',{staticClass:"mr-2 ml-2"},[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.isPinned),callback:function ($$v) {_vm.isPinned=$$v},expression:"isPinned"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","color":"cyan","rows":"2","auto-grow":"","label":_vm.$t('notes_label')},model:{value:(_vm.noteText),callback:function ($$v) {_vm.noteText=$$v},expression:"noteText"}})],1)],1)],1),_c(VCardActions,[(_vm.editingNote)?_c(VBtn,{attrs:{"color":"red","small":"","type":"submit","loading":_vm.isDeleteLoading,"text":"","disabled":!_vm.isEditEnabled},on:{"click":_vm.deleteTreatment}},[_vm._v("\n        "+_vm._s(_vm.$t('delete_button'))+"\n      ")]):_vm._e(),_c(VSpacer),_c(VBtn,{staticClass:"pl-5 pr-5",attrs:{"color":"cyan darken-1","type":"submit","loading":_vm.isSubmitLoading,"disabled":!_vm.isEditEnabled},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("\n        "+_vm._s(_vm.editingNote ? _vm.$t('edit_button') : _vm.$t('add_button'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }