

















































































import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import { Pinkas } from "@/modules/payment/types/pinkas.type";
import ReportApi from "../api/report.api";
import { ReportDocumentNumberResults } from "../types/report.type";

@Component({
})
export default class ReportDocumentNumberDialog extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  public isExportLoading = false;
  public pinkasId: number = null;
  public fromDate: string = null;
  public toDate: string = null;
  public displayFromDatePicker = false;
  public displayToDatePicker = false;
  public documentType: string = null;
  public reportResults: ReportDocumentNumberResults = null;

  public closeDialog() {
    this.$emit("close");
  }

  public updated() {
    this.enableAdjacentMonths();
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get fromDateFormatted() {
    return moment(this.fromDate).format("DD/MM/YYYY");
  }

  get toDateFormatted() {
    return moment(this.toDate).format("DD/MM/YYYY");
  }

  get datePickerMinDate() {
    return moment(this.pinkasList[0].created_at).format("YYYY-MM-DD");
  }

  public mounted() {
    // todo change after pinkas change
    if (moment().startOf("year").isBefore(this.pinkasList[0].created_at)) {
      this.fromDate = moment(this.pinkasList[0].created_at).format("YYYY-MM-DD");
    } else {
      this.fromDate = moment().startOf("year").format("YYYY-MM-DD");
    }
    this.toDate = moment().format("YYYY-MM-DD");
    this.pinkasId = this.pinkasList[0].id;
    this.documentType = "heshbonit_mas";
  }

  public async onGenerateReportClicked() {
    this.isExportLoading = true;

    try {
      this.reportResults = await ReportApi.getDocumentNumberReport(this.pinkasId, this.documentType, this.fromDate, this.toDate);
      this.isExportLoading = false;
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportLoading = false;
    }
  }

  get documentList() {
    return [
      { value: "heshbonit_mas", text: this.$t("heshbonit_mas") },
      { value: "heshbonit_mas_kabala", text: this.$t("heshbonit_mas_kabala") },
      { value: "kabala", text: this.$t("kabala") },
      { value: "heshbonit_zikoi", text: this.$t("heshbonit_zikoi") },
    ];
  }

}
