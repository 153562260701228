






















import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Pinkas } from "@/modules/payment/types/pinkas.type";
import { Chart } from "highcharts-vue";
import ReportApi from "../../api/report.api";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";

@Component({
  components: {
    Chart,
  }
})
export default class YearlyIncomesChart extends Vue {
  @Getter("auth/pinkasList") pinkasList: Pinkas[];
  public pinkasId: number = null;
  private activeRequest: CancelTokenSource = null;
  public year: number = null;
  public isLoading = false;
  public yearlyIncomesData: { [month: string]: number } = null;

  @Watch("year")
  @Watch("pinkasId")
  onFiltersChanged() {
    this.reload();
  }

  public async reload() {
    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();
    this.isLoading = true;
    this.yearlyIncomesData = null;

    try {
      this.yearlyIncomesData = await ReportApi.getYearlyIncomesReport(this.pinkasId, this.year, this.activeRequest);
      this.isLoading = false;
      this.$nextTick(() => {
        if (this.$refs.chartComponent && (this.$refs.chartComponent as any).chart && (this.$refs.chartComponent as any).chart.reflow) {
          (this.$refs.chartComponent as any).chart.reflow();
        }
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }

  }

  public async mounted() {
    this.pinkasId = this.pinkasList[0].id;
    this.year = parseInt(moment().format("YYYY"));
    await this.reload();
  }

  get yearOptions() {
    const options: { text: string; value: number }[] = [];

    for (let i = 1; i >= -4; i--) {
      const year = parseInt(moment().add(i, "years").format("YYYY"));
      options.push({
        text: year.toString(),
        value: year,
      });
    }

    return options;
  }

  get chartOptions() {
    return {
      chart: {
        type: "column"
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: this.xAxisCategories,
        labels: {
          useHTML: true,
        }
      },
      yAxis: {
        min: 0,
        max: this.yAxisMax,
        title: {
          enabled: false,
        },
      },
      tooltip: {
        pointFormatter: function () { return "<b>" + ((this as any).y as number).toLocaleString() + "₪</b>"; },
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          animation: false
        },
        column: {
          colorByPoint: true,
          dataLabels: {
            inside: true,
            enabled: true,
            formatter: function () {
              return ((this as any).y >= 1000 ? Math.floor((this as any).y / 1000).toLocaleString() + "k" : (this as any).y.toLocaleString());
            },
          }
        }
      },
      colors: this.colors,
      series: [
        {
          name: "הכנסות",
          data: this.seriesData,
        }
      ]
    };
  }

  get seriesData() {
    return Object.values(this.yearlyIncomesData);
  }

  get yAxisMax() {
    return Math.max(...this.seriesData);
  }

  get colors() {
    const colors: string[] = [];

    Object.keys(this.yearlyIncomesData).forEach(month => {
      if (moment(month).endOf("month").isBefore(moment())) {
        colors.push("#4a85e8");
      } else if (moment(month).isBefore(moment())) {
        colors.push("#093685");
      } else {
        colors.push("#bed6ff");
      }
    });

    return colors;
  }

  get xAxisCategories() {
    const categories: string[] = [];
    Object.keys(this.yearlyIncomesData).forEach(yearMonth => {
      const [year, month] = yearMonth.split("-");
      categories.push(this.$t(`months.${parseInt(month) - 1}`) + " " + year.slice(-2));
    });
    return categories;
  }
}
