export const REPORTS_ROUTE_NAME = "reports-page";
export const REPORTS_ROUTE_PATH = "reports";

export const REPORT_TREATMENTS_MONTHLY_ROUTE_NAME =
  "report-treatments-monthly-page";
export const REPORT_TREATMENTS_MONTHLY_ROUTE_PATH =
  "reports/treatments-monthly";

export const REPORT_DOCTOR_CALENDAR_ROUTE_NAME = "report-doctor-calendar-page";
export const REPORT_DOCTOR_CALENDAR_ROUTE_PATH = "reports/doctor-calendar";

export const REPORT_APPOINTMENTS_MONTHLY_ROUTE_NAME =
  "report-appointments-monthly-page";
export const REPORT_APPOINTMENTS_MONTHLY_ROUTE_PATH =
  "reports/appointments-monthly";

export const REPORT_PATIENTS_DEBTS_ROUTE_NAME = "report-patients-debts-page";
export const REPORT_PATIENTS_DEBTS_ROUTE_PATH = "reports/patients-debts";

export const REPORT_ALL_RECEIPTS_ROUTE_NAME = "report-all-receipts-page";
export const REPORT_ALL_RECEIPTS_ROUTE_PATH = "reports/all-receipts";

export const REPORT_ALL_INCOMES_ROUTE_NAME = "report-all-incomes-page";
export const REPORT_ALL_INCOMES_ROUTE_PATH = "reports/all-incomes";

export const REPORT_OVERVIEW_ROUTE_NAME = "report-overview-page";
export const REPORT_OVERVIEW_ROUTE_PATH = "reports/overview";

export const REPORT_KUPA_ROUTE_NAME = "report-clinic-kupa-page";
export const REPORT_KUPA_ROUTE_PATH = "reports/clinic-kupa";
