import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient-tags-component"},[_c('div',{staticClass:"d-inline-block"},_vm._l((_vm.patientTags),function(tag,index){return _c(VChip,{key:index,staticClass:"contrast-text",attrs:{"color":tag.color}},[_vm._v("\n      "+_vm._s(tag.name)+"\n    ")])}),1),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"label":"","x-small":"","color":"cyan","loading":_vm.loading}},'VBtn',attrs,false),on),[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-label")]),_vm._v("\n        "+_vm._s(_vm.$t('tags'))+"\n      ")],1)]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},[_c(VList,{attrs:{"max-height":"460"}},[_c(VListItem,{attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.showTagForm()}}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('create_tag')))])],1),_c(VDivider),_c(VListItemGroup,{staticStyle:{"background":"white"},attrs:{"multiple":""},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}},_vm._l((_vm.tags),function(tag,index){return _c(VListItem,{key:index,on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"cyan darken-2"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c(VChip,{staticClass:"contrast-text",attrs:{"small":"","color":tag.color,"ß":""}},[_vm._v("\n                  "+_vm._s(tag.name)+"\n                ")])],1)],1)]}}],null,true)})}),1)],1)],1),(_vm.showTagFormDialog)?_c('TagForm',{on:{"close":function($event){_vm.showTagFormDialog = false},"reload":_vm.tagCreated}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }