






















































































































































































































import PatientApi from "../api/patient.api";
import PatientNotes from "../components/PatientNotes.vue";
import PatientAppointments from "../components/PatientAppointments.vue";
import PatientPlans from "../components/PatientPlans.vue";
import PatientTags from "../components/PatientTags.vue";
import DisplayPatientFiles from "@/modules/patient-file/components/patient-file/DisplayPatientFiles.vue";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { AppConfig } from "@/core/config/config";
import { Patient } from "@/modules/patient/types/patient.type";
import { Plan } from "@/modules/treatment/types/plan.type";
import PatientPayments from "@/modules/patient/components/PatientPayments.vue";
import PatientWhatsappChatDialog from "@/modules/whatsapp/components/PatientWhatsappChatDialog.vue";
import PatientTreatments from "../components/PatientTreatments.vue";
import PatientQuickActionsButton from "../components/PatientQuickActionsButton.vue";
import ErrorLogo from "@/components/Logo/ErrorLogo.vue";
import { PATIENTS_ROUTE_NAME } from "../constants/route-config";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import EchoService from "@/serivce/echo-service";
import { Note } from "../types/note.type";
import PatientNoteFormDialog from "../components/PatientNoteFormDialog.vue";
import PatientHiddenNoteFormDialog from "../components/PatientHiddenNote/PatientHiddenNoteFormDialog.vue";
import PatientExportDialog from "../components/PatientActionDialog/PatientExportDialog.vue";
import PatientFamilyDialog from "../components/PatientActionDialog/PatientFamilyDialog.vue";
import { PatientTreatment } from "../types/patient-treatment.type";
import PatientCreateLetterDialog from "../components/PatientActionDialog/PatientCreateLetterDialog.vue";
import ReceivePaymentDialog from "@/modules/payment/components/ReceivePaymentDialog.vue";
import PatientFileExplorerDialog from "@/modules/patient-file/components/patient-file-explorer/PatientFileExplorerDialog.vue";
import SmartMirrorButton from "@/modules/smartmirror/components/SmartMirrorButton.vue";
import moment from "moment";

const PatientInfo = () => import("@/modules/patient/components/PatientInfo/" + AppConfig.customPatientInfoComponent + ".vue");
const PatientForm = () => import("@/modules/patient/components/PatientForm/" + AppConfig.customPatientFormComponent + ".vue");

@Component({
  components: {
    SmartMirrorButton,
    ReceivePaymentDialog,
    ErrorLogo,
    PatientQuickActionsButton,
    PatientPayments,
    PatientNotes,
    PatientTags,
    DisplayPatientFiles,
    PatientPlans,
    PatientAppointments,
    PatientTreatments,
    PatientCreateLetterDialog,
    PatientInfo,
    PatientForm,
    PatientWhatsappChatDialog,
    PatientNoteFormDialog,
    PatientExportDialog,
    PatientFamilyDialog,
    PatientFileExplorerDialog,
    PatientHiddenNoteFormDialog,
  },
})
export default class PatientPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") public isRtl;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isWhatsappBotEnabled") isWhatsappBotEnabled: boolean;
  @Getter("auth/isPlansEnabled") isPlansEnabled: boolean;
  @Getter("auth/defaultLetterSignature") defaultLetterSignature: string;
  @Getter("auth/letterFromPatientTreatmentContentTemplate") letterFromPatientTreatmentContentTemplate: string;
  @Getter("auth/isSmartMirrorEnabled") isSmartMirrorEnabled: boolean;
  @Getter("auth/isShowingFamilyButtonInPatientPage") isShowingFamilyButtonInPatientPage: boolean;

  public isDeleteLoading = false;
  public isOpenReceivePaymentDialogLoading = false;
  public activeTab = 0;
  public isLoadingPatient = false;
  public editPatient = false;
  public patientId = null;
  public displayExportDialog = false;
  public displayFamilyDialog = false;
  public displayWhatsappChatDialog = false;
  public patient: Patient = null;
  public isEditingNote = false;
  public editingNote: Note = null;
  public showPatientDynamicLetterDialog = false;
  public createLetterTitle = "";
  public createLetterSubject = "";
  public createLetterTo = "";
  public createLetterContent = "";
  public createLetterFileIds = [];
  public isDisplayReceivePaymentDialog = false;
  public isDisplayPatientFileExplorerDialog = false;
  public isDisplayHiddenNote = false;

  public onEditNoteClicked(note: Note) {
    this.isEditingNote = true;
    this.editingNote = note;
  }

  public onAddNoteClicked() {
    this.isEditingNote = true;
    this.editingNote = null;
  }

  public onOpenHiddenNote() {
    this.isDisplayHiddenNote = true;
  }

  public closeNoteFormDialog() {
    this.editingNote = null;
    this.isEditingNote = false;
  }

  public onHiddenNoteUpdated(hiddenNote: string) {
    this.patient.hidden_note = hiddenNote;
  }

  public async onNoteUpdated(note: Note) {
    const index = this.patient.notes.findIndex(({ id }) => (id === note.id));
    this.patient.notes[index].pinned = note.pinned;
    this.patient.notes[index].text = note.text;
    this.patient.notes[index].user_id = note.user_id;
    this.patient.notes[index].created_at = note.created_at;
    this.patient.notes[index].updated_at = note.updated_at;
    this.$toastr.s(this.$t("toastr_note_updated"));
  }

  public async onNoteCreated(note: Note) {
    this.patient.notes.unshift(note);
    this.$toastr.s(this.$t("toastr_note_created"));
  }

  public async onNoteDeleted(noteId: number) {
    const index = this.patient.notes.findIndex(({ id }) => (id === noteId));
    this.patient.notes.splice(index, 1);

    this.$toastr.s(this.$t("note_deleted_toastr"));
  }

  public async refreshPatientFiles() {
    const patientData = await PatientApi.get(this.patientId);
    this.patient.files = patientData.files;
    this.patient.folders = patientData.folders;
  }

  mounted() {
    this.patientId = this.$route.params.id;
    this.loadPatient();
    this.listenPatientFilesChange();
  }

  @Watch("$route")
  watchRoute() {
    this.displayFamilyDialog = false;
    this.displayExportDialog = false;
    this.isDisplayPatientFileExplorerDialog = false;
    this.isDisplayReceivePaymentDialog = false;
    this.displayWhatsappChatDialog = false;

    this.patientId = this.$route.params.id;
    this.patient = null;
    this.activeTab = 0;
    this.loadPatient();
  }

  private timeoutIntervalSet = false;

  public async loadPatient() {
    this.isLoadingPatient = true;

    this.patientId = parseInt(this.patientId);
    if (!this.patientId) {
      this.$toastr.e(this.$t("temporary_error"));
      this.$router.push({ name: PATIENTS_ROUTE_NAME });
      return;
    }

    try {
      this.patient = await PatientApi.get(this.patientId);
    } catch (err) {
      if (!this.timeoutIntervalSet) {
        setTimeout(() => {
          this.timeoutIntervalSet = false;
          this.loadPatient();
        }, 5000);
        this.timeoutIntervalSet = true;
      }
    } finally {
      this.isLoadingPatient = false;
    }
  }

  public async onPaymentCanceled() {
    this.loadPatient();
  }

  public async onPaymentReceived() {
    if (this.$store.getters["auth/clinicName"] === "מרפאת רומנו") {
      this.$toastr.Add({
        title: "תזכורת לטופס הסכמה לטיפול",
        msg: "תזכורת לשלוח ל" + this.patient.first_name + " " + this.patient.last_name + " טופס הסכמה לטיפול",
        timeout: 0,
        position: "toast-bottom-full-width",
        type: "info",
        style: { backgroundColor: "blue", width: "80%" }
      });
    }
    this.loadPatient();
  }

  public async onPaymentRebalanced() {
    this.loadPatient();
  }

  public async onOpenPaymentReceiveDialog() {
    try {
      this.isOpenReceivePaymentDialogLoading = true;
      await this.loadPatient();
    } catch (err) {
      console.log(err);
    }

    this.isOpenReceivePaymentDialogLoading = false;
    this.isDisplayReceivePaymentDialog = true;
  }

  public closeWhatsappChatDialog() {
    this.displayWhatsappChatDialog = false;
  }

  public onPatientUpdated() {
    this.loadPatient();
    this.closePatientForm();
  }

  destroyed() {
    EchoService.stopListenImagingScanUploadedPush();
  }

  private listenPatientFilesChange() {
    EchoService.listenImagingScanUploadedPush(async (eventData: { patientId: number; patientFileId: number }) => {
      if (eventData.patientId !== this.patientId) {
        return;
      }

      if (!this.patient) {
        setTimeout(async () => {
          if (!this.patient) {
            return;
          }
          await this.refreshPatientFiles();
        }, 6000);
        return;
      }

      const existingFile = this.patient.files.find(({ id }) => (id === eventData.patientFileId));
      if (existingFile) {
        try {
          // TODO check not editing?
          const patientData = await PatientApi.get(this.patientId);
          const newFile = patientData.files.find(({ id }) => (id === eventData.patientFileId));
          existingFile.temporary_url = newFile.temporary_url;
          existingFile.download_url = newFile.download_url;
        } catch (err) {
          // TODO log?
        }
      } else {
        try {
          // TODO check not editing?
          const patientData = await PatientApi.get(this.patientId);
          const newFile = patientData.files.find(({ id }) => (id === eventData.patientFileId));
          this.patient.files.unshift(newFile);
        } catch (err) {
          // TODO log?
        }
      }
    });
  }

  public async unachiveClicked() {
    this.isDeleteLoading = true;
    try {
      await PatientApi.unarchive(this.patient.id);
      this.$toastr.s(this.$t("unarchived_toastr"));
      this.isDeleteLoading = false;
      this.loadPatient();

    } catch (err) {
      this.$toastr.e(this.$t("unarchived_error"));
      this.isDeleteLoading = false;
    }
  }

  public async deleteClicked() {
    const res = await this.$confirm(
      this.$t("delete_confirm_message", {
        "first_name": this.patient.first_name,
        "last_name": this.patient.last_name
      }).toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("delete_ok_btn").toString(),
        buttonFalseText: this.$t("delete_cancel_btn").toString(),
      });

    if (!res)
      return;

    this.isDeleteLoading = true;
    try {
      await PatientApi.delete(this.patient.id);
      this.$toastr.s(this.$t("deleted_toastr"));
      this.isDeleteLoading = false;
      this.$router.push({ name: PATIENTS_ROUTE_NAME });

    } catch (err) {
      this.$toastr.e(this.$t("delete_error"));
      this.isDeleteLoading = false;
    }
  }

  get isBackEnabled() {
    return window.history.length;
  }

  get patientBalance() {
    let balance = 0;

    this.patient.treatments.forEach(treatment => {
      balance -= treatment.price - treatment.discount;
    });
    this.patient.payments.forEach(payment => {
      if (!payment.deleted_at) {
        balance += parseFloat(payment.amount.toString());
      }
    });

    return balance;
  }

  get familyBalance() {
    let familyBalance = 0;

    this.patient.family_balance.forEach(({ balance }) => {
      familyBalance += balance;
    });

    return familyBalance + this.patientBalance;
  }

  public onBackClicked() {
    this.$router.go(-1);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get isBirthdayWeek() {
    if (!this.patient || !this.patient.birth_date) {
      return false;
    }

    return (this.daysDiffToBirthday(this.patient.birth_date) as number) <= 7;
  }

  public closePatientForm() {
    this.editPatient = false;
  }

  public planCreated(plan: Plan) {
    this.patient.plans.unshift(plan);
  }

  public planDeleted(planId: number) {
    const planIndex = this.patient.plans.map(plan => (plan.id)).indexOf(planId);
    if (planIndex !== -1) {
      this.patient.plans.splice(planIndex, 1);
    }
  }

  get hasActivePlan() {
    return this.patient.plans
      // .filter(plan => (!plan.completed_at))
      .length > 0;
  }

  public async onCreateLetter(patientTreatment: PatientTreatment) {
    this.createLetterSubject = "הנדון: " + this.patient.first_name + " " + this.patient.last_name + (this.patient.id_number ? " - ת.ז. " + this.patient.id_number : "");
    this.createLetterTitle = this.createLetterSubject;
    this.createLetterTo = "לכל המעוניין"; // TODO
    this.createLetterContent = this.letterFromPatientTreatmentContentTemplate
      .replaceAll("{treatmentNotes}", patientTreatment.notes)
      .replaceAll("{treatmentDate}", moment(patientTreatment.created_at).format("DD-MM-YYYY"))
      .replaceAll("{treatmentClinician}", this.userName(patientTreatment.user_id));
    this.showPatientDynamicLetterDialog = true;
  }

  public async onUploadProfilePicture(formData: FormData) {
    try {
      await PatientApi.uploadProfilePicture(formData);
      this.$toastr.s(this.$t("profile_picture_uploaded_toastr"));
      await this.loadPatient();
    } catch {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public async onRemoveProfilePicture() {
    const res = await this.$confirm(
      this.$t("remove_profile_picture_message").toString(),
      {
        buttonTrueColor: "red",
        buttonTrueText: this.$t("remove_profile_picture_ok_btn").toString(),
        buttonFalseText: this.$t("remove_profile_picture_cancel_btn").toString(),
      });

    if (!res) {
      return;
    }

    try {
      await PatientApi.removeProfilePicture(this.patientId);
      this.$toastr.s(this.$t("profile_picture_removed_toastr"));
      await this.loadPatient();
    } catch {
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public onOpenPatientFileExplorerClicked() {
    this.isDisplayPatientFileExplorerDialog = true;
  }

  public onClosePatientFileExplorerClicked() {
    this.isDisplayPatientFileExplorerDialog = false;
  }

  public onCreateLetterClicked(patientFileIds: number[]) {
    this.createLetterFileIds = patientFileIds;
    this.isDisplayPatientFileExplorerDialog = false;

    this.createLetterSubject = "הנדון: " + this.patient.first_name + " " + this.patient.last_name + (this.patient.id_number ? " - ת.ז. " + this.patient.id_number : "");
    this.createLetterTitle = this.createLetterSubject;
    this.createLetterTo = "לכל המעוניין"; // TODO
    this.createLetterContent = "";
    this.showPatientDynamicLetterDialog = true;
  }
}
