

























































































import { Component, Watch } from "vue-property-decorator";
import moment from "moment";
import TimeClockApi from "../api/time-clock.api";
import { TimeClockEntry } from "../types/time-clock.type";
import TimeClockManageForm from "../components/time-entry/TimeClockManageForm.vue";
import EditTimeClockEntry from "../components/time-entry/EditTimeClockEntry.vue";
import { Getter } from "vuex-class";
import { DataTableHeader } from "vuetify";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { date, time } from "@/filters/date.filter";
import { User } from "@/modules/users/types/user.type";
import TimeClockLoggingDialog from "../components/time-entry/TimeClockLoggingDialog.vue";

@Component({
  filters: {
    date,
    time,
  },
  components: {
    TimeClockManageForm,
    EditTimeClockEntry,
    TimeClockLoggingDialog,
  },
})
export default class ManageTimeClockPage extends mixins(SmileMixin) {
  @Getter("auth/fullName") public fullName: string;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isAdmin") isUserAdmin: boolean;

  public entries: TimeClockEntry[] = [];
  public isFetchingEntries = false;
  public isAddingFormOpen = false;
  public dispalyUserId: number = null;
  public dateFilter = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
  public dateMenuOpen = false;
  public isLoggingFormOpen = false;

  created() {
    this.loadEntries();
  }

  get formattedDateFilter() {
    const [year, month] = this.dateFilter.split("-");
    return this.$t(`months.${parseInt(month) - 1}`) + " " + year;
  }
  public formattedDay(date: string) {
    const dayOfWeek = new Date(date).getDay();
    return this.$t(`days_of_week.${dayOfWeek}`);
  }

  @Watch("dateFilter")
  public loadEntries() {
    this.isFetchingEntries = true;
    const [year, month] = this.dateFilter.split("-");
    const yearMonth = `${year}-${month}`;

    TimeClockApi
      .getAll({
        user_id: -1,
        year_month: yearMonth
      })
      .then(data => {
        if (yearMonth === data.year_month) {
          this.entries = data.entries;
        }
      })
      .finally(() => this.isFetchingEntries = false);
  }

  public mounted() {
    if (this.userOptions.find(({ value }) => (value === this.$store.getters["auth/userId"]))) {
      this.dispalyUserId = this.$store.getters["auth/userId"];
    }
  }

  public createdEntry() {
    this.loadEntries();
  }

  public updateEntry(clockEntry: TimeClockEntry) {
    const updatedEntryId = this.entries.findIndex((entry) => entry.id === clockEntry.id);

    if (updatedEntryId !== -1) {
      this.$set(this.entries, updatedEntryId, clockEntry);
    }
  }

  public removeEntry(entryId: number) {
    const toBeRemovedEntryIndex = this.entries.findIndex((entry) => entryId === entry.id);

    if (toBeRemovedEntryIndex !== -1) {
      this.entries.splice(toBeRemovedEntryIndex, 1);
    }
  }

  public errors = false;
  public isEditingEntry = false;
  public processedEntry: TimeClockEntry = null;
  public headers: DataTableHeader[] = [
    { width: "14%", text: this.$t("headers.date").toString(), value: "start" },
    { width: "14%", text: this.$t("headers.day").toString(), value: "day" },
    { width: "14%", text: this.$t("headers.user_id").toString(), value: "user_id" },
    { width: "14%", text: this.$t("headers.type").toString(), value: "type_id" },
    { width: "14%", text: this.$t("headers.start_time").toString(), value: "start_time", sortable: false },
    { width: "14%", text: this.$t("headers.end_time").toString(), value: "end_time", sortable: false },
    { width: "14%", text: this.$t("headers.duration").toString(), value: "duration", sortable: false },
    { width: "14%", text: this.$t("headers.notes").toString(), value: "notes", sortable: false },
    { width: "14%", text: "", value: "actions", sortable: false }
  ];

  public rowClass(timeClickEntry: TimeClockEntry) {
    if (moment(timeClickEntry.end).format("YYYY-MM-DD") !== (moment(timeClickEntry.start).format("YYYY-MM-DD"))) {
      return "orange lighten-3";
    }
    if (timeClickEntry.end === timeClickEntry.start && moment(timeClickEntry.end).format("YYYY-MM-DD") < (moment().format("YYYY-MM-DD")) && !timeClickEntry.notes) {
      return "orange lighten-3";
    }
    return "";
  }

  get filteredEntries() {
    if (this.dispalyUserId) {
      return this.entries.filter(({ user_id }) => (this.dispalyUserId === user_id));
    }
    return this.entries;
  }

  public getDuration(start: string, end: string) {
    const duration = moment.duration(moment(end).diff(start));
    let humanDuration = "";

    if (duration.hours() + duration.days()) {
      humanDuration += `${duration.hours() + (duration.days() * 24)} ${this.$t("duration_hours")}`;

      if (duration.minutes()) {
        humanDuration += ` ${this.$t("duration_concat")} `;
      }
    }

    if (duration.minutes()) {
      humanDuration += `${duration.minutes()} ${this.$t("duration_minutes")} `;
    }

    return humanDuration;
  }

  get userOptions() {
    return (this.$store.getters["auth/users"] as User[])
      .filter(({ deleted_at }) => (!deleted_at))
      .filter(({ is_time_clock_enabled }) => (is_time_clock_enabled))
      .map(user => ({
        "text": user.name,
        "value": user.id
      }));
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

}
