












































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Tag } from "@/modules/patient/types/tag.type";
import PatientTagApi from "@/serivce/api/patient-tag-api";
import TagForm from "@/modules/settings/components/tag/TagForm.vue";

@Component({
  components: {
    TagForm,
  }
})
export default class PatientTags extends Vue {
  @Prop({ required: true }) public patientId: number;
  @Prop({ required: true }) public patientTags: Tag[];
  @Getter("auth/tags") public tags: Tag[];

  public showTagFormDialog = false;
  public loading = false;
  public menuOpened = false;
  public selectedTags: number[] = [];

  mounted() {
    for (let i = 0; i < this.tags.length; i++) {
      if (this.patientTags.map(tag => (tag.id)).indexOf(this.tags[i].id) !== -1) {
        this.selectedTags.push(i);
      }
    }
  }

  update(newPatientTags: Tag[]) {
    this.loading = true;

    const newPatientTagIds = newPatientTags.map(tag => (tag.id));
    PatientTagApi.update(this.patientId, newPatientTagIds)
      .then(() => {
        this.$emit("tagsUpdated", newPatientTags);
        this.loading = false;
      });
  }

  @Watch("menuOpened")
  onMenuOpenedChanged(newStatus: boolean) {
    if (!newStatus) {
      const newPatientTags = [];
      for (let i = 0; i < this.tags.length; i++) {
        if (this.selectedTags.indexOf(i) !== -1)
          newPatientTags.push(this.tags[i]);
      }

      // check if patient tags changed
      const isEqual = (JSON.stringify(newPatientTags.map(tag => tag.id).sort()) === JSON.stringify(this.patientTags.map(tag => tag.id).sort()));
      if (!isEqual)
        this.update(newPatientTags);
    }
  }

  public tagCreated(tag: Tag) {
    const newTagIndex = this.tags.findIndex(t => (t.id === tag.id));
    this.selectedTags.push(newTagIndex);
    this.onMenuOpenedChanged(false);
  }

  public showTagForm() {
    this.showTagFormDialog = true;
  }
}
