



























































































































































import { Component, Vue } from "vue-property-decorator";
import ReportItem from "../components/ReportItem.vue";
import { REPORT_DOCTOR_CALENDAR_ROUTE_NAME, REPORT_TREATMENTS_MONTHLY_ROUTE_NAME, REPORT_APPOINTMENTS_MONTHLY_ROUTE_NAME, REPORT_ALL_RECEIPTS_ROUTE_NAME, REPORT_ALL_INCOMES_ROUTE_NAME, REPORT_PATIENTS_DEBTS_ROUTE_NAME, REPORT_KUPA_ROUTE_NAME, REPORT_OVERVIEW_ROUTE_NAME } from "../constants/route-config";
import ReportOpenFormatDialog from "../components/ReportOpenFormatDialog.vue";
import ReportDocumentNumberDialog from "../components/ReportDocumentNumberDialog.vue";
import SendTimeClockMonthlyReport from "@/modules/time-clock/components/time-entry/SendTimeClockMonthlyReport.vue";
import ReportHashavshevetDialog from "../components/ReportHashavshevetDialog.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    ReportItem,
    ReportDocumentNumberDialog,
    ReportOpenFormatDialog,
    SendTimeClockMonthlyReport,
    ReportHashavshevetDialog,
  }
})
export default class ReportsPage extends Vue {
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isTimeClockFeatureEnabled") isTimeClockFeatureEnabled: boolean;
  @Getter("auth/isKupaReportFeatureEnabled") isKupaReportFeatureEnabled: boolean;
  @Getter("auth/isAdmin") isUserAdmin: boolean;
  @Getter("auth/shouldDisplayFinanceReports") shouldDisplayFinanceReports: boolean;
  @Getter("auth/shouldDisplayKupaReport") shouldDisplayKupaReport: boolean;
  @Getter("auth/isAccountant") isAccountant: boolean;

  public showOpenFormatReportDialog = false;
  public showDocumentNumberReportDialog = false;
  public showTimeClockReportDialog = false;
  public showHashavshevetReportDialog = false;

  get reportDoctorCalendarRouteName() {
    return REPORT_DOCTOR_CALENDAR_ROUTE_NAME;
  }

  get reportTreatmentsMonthlyRouteName() {
    return REPORT_TREATMENTS_MONTHLY_ROUTE_NAME;
  }

  get reportAllIncomesRouteName() {
    return REPORT_ALL_INCOMES_ROUTE_NAME;
  }

  get reportPatientsDebtsRouteName() {
    return REPORT_PATIENTS_DEBTS_ROUTE_NAME;
  }

  get reportKupaRouteName() {
    return REPORT_KUPA_ROUTE_NAME;
  }

  get reportAppointmentsMonthlyRouteName() {
    return REPORT_APPOINTMENTS_MONTHLY_ROUTE_NAME;
  }

  get reportOverviewRouteName() {
    return REPORT_OVERVIEW_ROUTE_NAME;
  }

  get reportAllReceiptsReportRouteName() {
    return REPORT_ALL_RECEIPTS_ROUTE_NAME;
  }
}
