






























































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import WaitingAppointmentApi from "../../api/waiting-appointment.api";
import { WaitingAppointment } from "../../types/waiting-appointment.type";
import { Getter } from "vuex-class";
import { User } from "@/modules/users/types/user.type";
import { Patient } from "@/modules/patient/types/patient.type";
import axios, { CancelTokenSource } from "axios";
import PatientApi from "@/modules/patient/api/patient.api";
import { AppointmentType } from "../../types/appointment-type.type";

@Component({
  components: {
  },
})
export default class WaitingListFormDialog extends mixins(SmileMixin) {
  @Prop({ required: false }) public editWaitingAppointment: WaitingAppointment;
  @Prop({ required: true }) public waitingAppointments: WaitingAppointment[];

  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/therapistUsers") therapistUsers: User[];
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;

  private activePatientSearchRequest: CancelTokenSource = null;
  public hideNoData = true;
  public isFormLoading = false;
  public isLoadingPatient = false;
  public showErrors = false;
  public userId: number = null;
  public patientSearch = "";
  public urgency = 2;
  public disablePatientSearch = false;
  public isLoadingDelete = false;
  public notes: string = null;
  public patient: Patient = null;
  public appointmentDurationMinutes = 15;
  public patientSearchEntries: Patient[] = [];
  public isAvailabilitySunday = false;
  public isAvailabilityMonday = false;
  public isAvailabilityTuesday = false;
  public isAvailabilityWednesday = false;
  public isAvailabilityThursday = false;
  public isAvailabilityFriday = false;
  public isAvailabilitySaturday = false;

  public availabilitySundayFrom = "07:00";
  public availabilityMondayFrom = "07:00";
  public availabilityTuesdayFrom = "07:00";
  public availabilityWednesdayFrom = "07:00";
  public availabilityThursdayFrom = "07:00";
  public availabilityFridayFrom = "07:00";
  public availabilitySaturdayFrom = "07:00";

  public availabilitySundayTo = "20:00";
  public availabilityMondayTo = "20:00";
  public availabilityTuesdayTo = "20:00";
  public availabilityWednesdayTo = "20:00";
  public availabilityThursdayTo = "20:00";
  public availabilityFridayTo = "20:00";
  public availabilitySaturdayTo = "20:00";

  public availabilityHoursOptions = [
    "07:00",
    "07:15",
    "07:30",
    "07:45",

    "08:00",
    "08:15",
    "08:30",
    "08:45",

    "09:00",
    "09:15",
    "09:30",
    "09:45",

    "10:00",
    "10:15",
    "10:30",
    "10:45",

    "11:00",
    "11:15",
    "11:30",
    "11:45",

    "12:00",
    "12:15",
    "12:30",
    "12:45",

    "13:00",
    "13:15",
    "13:30",
    "13:45",

    "14:00",
    "14:15",
    "14:30",
    "14:45",

    "15:00",
    "15:15",
    "15:30",
    "15:45",

    "16:00",
    "16:15",
    "16:30",
    "16:45",

    "17:00",
    "17:15",
    "17:30",
    "17:45",

    "18:00",
    "18:15",
    "18:30",
    "18:45",

    "19:00",
    "19:15",
    "19:30",
    "19:45",

    "20:00",
    "20:15",
    "20:30",
    "20:45",

    "21:00",
    "21:15",
    "21:30",
    "21:45",
  ];

  get isWaitingAppointmentAlreadyExists() {
    if (!this.patient || !this.userId) {
      return false;
    }

    const sameWaitingAppointment = this.waitingAppointments.find(({ user_ids, patient_id, id }) => (user_ids.includes(this.userId) && patient_id === this.patient.id && (!this.editWaitingAppointment || id !== this.editWaitingAppointment.id)));
    return !!sameWaitingAppointment;
  }

  @Watch("patientSearch")
  public async onPatientSearchChanged(query) {
    if (this.disablePatientSearch) {
      return;
    }

    this.hideNoData = true;
    this.isLoadingPatient = true;
    this.patientSearchEntries = [];

    // Lazily load input items
    if (!query || query.length === 0) {
      this.isLoadingPatient = false;
      return;
    }

    if (this.activePatientSearchRequest) {
      this.activePatientSearchRequest.cancel();
    }
    this.activePatientSearchRequest = axios.CancelToken.source();

    try {
      const response = await PatientApi.quickSearch(query, false, this.activePatientSearchRequest);
      this.hideNoData = response.data.data.length !== 0;
      this.patientSearchEntries = response.data.data;
      this.isLoadingPatient = false;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoadingPatient = false;
    }
  }

  @Watch("patient")
  onPatientChanged(selectedPatientId: number) {
    const patient = this.patientSearchEntries.find(({ id }) => (id === selectedPatientId));
    if (!patient) {
      // TODO log to sentry / coralogix
      return;
    }

    this.disablePatientSearch = true;
    setTimeout(() => {
      this.disablePatientSearch = false;
    }, 500);

    this.patient = patient;
    this.hideNoData = true;
  }

  public mounted() {
    if (this.editWaitingAppointment) {
      this.userId = this.editWaitingAppointment.user_ids[0];
      this.urgency = this.editWaitingAppointment.urgency;
      this.notes = this.editWaitingAppointment.notes;
      this.appointmentDurationMinutes = this.editWaitingAppointment.appointment_duration_minutes;
      this.patient = this.editWaitingAppointment.patient;
      this.patientSearch = this.fullName(this.patient);

      if (this.editWaitingAppointment.availability_sunday.length) {
        this.isAvailabilitySunday = true;
        this.availabilitySundayFrom = this.editWaitingAppointment.availability_sunday[0].from;
        this.availabilitySundayTo = this.editWaitingAppointment.availability_sunday[0].to;
      }

      if (this.editWaitingAppointment.availability_monday.length) {
        this.isAvailabilityMonday = true;
        this.availabilityMondayFrom = this.editWaitingAppointment.availability_monday[0].from;
        this.availabilityMondayTo = this.editWaitingAppointment.availability_monday[0].to;
      }

      if (this.editWaitingAppointment.availability_tuesday.length) {
        this.isAvailabilityTuesday = true;
        this.availabilityTuesdayFrom = this.editWaitingAppointment.availability_tuesday[0].from;
        this.availabilityTuesdayTo = this.editWaitingAppointment.availability_tuesday[0].to;
      }

      if (this.editWaitingAppointment.availability_wednesday.length) {
        this.isAvailabilityWednesday = true;
        this.availabilityWednesdayFrom = this.editWaitingAppointment.availability_wednesday[0].from;
        this.availabilityWednesdayTo = this.editWaitingAppointment.availability_wednesday[0].to;
      }

      if (this.editWaitingAppointment.availability_thursday.length) {
        this.isAvailabilityThursday = true;
        this.availabilityThursdayFrom = this.editWaitingAppointment.availability_thursday[0].from;
        this.availabilityThursdayTo = this.editWaitingAppointment.availability_thursday[0].to;
      }

      if (this.editWaitingAppointment.availability_friday.length) {
        this.isAvailabilityFriday = true;
        this.availabilityFridayFrom = this.editWaitingAppointment.availability_friday[0].from;
        this.availabilityFridayTo = this.editWaitingAppointment.availability_friday[0].to;
      }

      if (this.editWaitingAppointment.availability_saturday.length) {
        this.isAvailabilitySaturday = true;
        this.availabilitySaturdayFrom = this.editWaitingAppointment.availability_saturday[0].from;
        this.availabilitySaturdayTo = this.editWaitingAppointment.availability_saturday[0].to;
      }
    } else {
      const appointmentTypes = this.$store.getters["auth/appointmentTypes"] as AppointmentType[];
      if (appointmentTypes.length === 1) {
        this.appointmentDurationMinutes = appointmentTypes[0].duration_minutes;
      }

      if (this.therapistUsers.length === 1) {
        this.userId = this.therapistUsers[0].id;
      }
    }
  }

  get urgencyOptions() {
    return [3, 2, 1]
      .map(urgency => ({
        "text": this.$t("urgency." + urgency),
        "value": urgency
      }));
  }

  public closeDialog() {
    this.$emit("close");
  }

  public async onDeleteClicked() {
    const patientName = this.editWaitingAppointment.patient.first_name + " " + this.editWaitingAppointment.patient.last_name;
    const res = await this.$confirm(this.$t("delete_confirm_message", { name: patientName }).toString(), {
      buttonTrueColor: "red",
      buttonTrueText: this.$t("delete_ok_btn").toString(),
      buttonFalseText: this.$t("delete_cancel_btn").toString(),
    });

    if (!res) {
      return;
    }

    this.isLoadingDelete = true;
    try {
      await WaitingAppointmentApi.delete(this.editWaitingAppointment.id);
      this.$emit("waitingAppointmentDeleted", this.editWaitingAppointment.id);
      this.$toastr.s(this.$t("deleted_successfully"));
      this.isLoadingDelete = false;
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isLoadingDelete = false;
    }
  }

  public fullName(patient: Patient) {
    return `${patient.first_name} ${patient.last_name}`;
  }
  public async submitForm() {
    this.showErrors = true;

    if (!this.userId || !this.patient) {
      return;
    }

    this.isFormLoading = true;

    const payload = {
      "patient_id": this.patient.id,
      "user_ids": [this.userId],
      "urgency": this.urgency,
      "notes": this.notes,
      "appointment_duration_minutes": this.appointmentDurationMinutes,
      "availability_sunday": this.isAvailabilitySunday ? [{ from: this.availabilitySundayFrom, to: this.availabilitySundayTo }] : [],
      "availability_monday": this.isAvailabilityMonday ? [{ from: this.availabilityMondayFrom, to: this.availabilityMondayTo }] : [],
      "availability_tuesday": this.isAvailabilityTuesday ? [{ from: this.availabilityTuesdayFrom, to: this.availabilityTuesdayTo }] : [],
      "availability_wednesday": this.isAvailabilityWednesday ? [{ from: this.availabilityWednesdayFrom, to: this.availabilityWednesdayTo }] : [],
      "availability_thursday": this.isAvailabilityThursday ? [{ from: this.availabilityThursdayFrom, to: this.availabilityThursdayTo }] : [],
      "availability_friday": this.isAvailabilityFriday ? [{ from: this.availabilityFridayFrom, to: this.availabilityFridayTo }] : [],
      "availability_saturday": this.isAvailabilitySaturday ? [{ from: this.availabilitySaturdayFrom, to: this.availabilitySaturdayTo }] : [],
    };

    try {
      const waitingAppointment = this.editWaitingAppointment
        ? await WaitingAppointmentApi.update(this.editWaitingAppointment.id, payload)
        : await WaitingAppointmentApi.create(payload);


      this.$toastr.s(this.$t(this.editWaitingAppointment ? "waiting_appointment_updated" : "waiting_appointment_created"));
      this.isFormLoading = false;
      this.$emit(this.editWaitingAppointment ? "waitingAppointmentUpdated" : "waitingAppointmentCreated", waitingAppointment);
      this.closeDialog();
    } catch (err) {
      this.isFormLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

}
