import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMarkedSendEmail !== undefined)?_c(VCheckbox,{staticStyle:{"display":"inline-block"},attrs:{"value":_vm.isMarkedSendEmail,"color":"cyan"},on:{"change":_vm.markEmail}}):_vm._e(),(!_vm.isAutoComplete)?_c(VTextField,{style:([!_vm.isFullWidth && { display: 'inline-block', width: '250px', maxWidth: 'calc(100% - 34px)' }]),attrs:{"error":_vm.isInvalidEmail,"label":_vm.inputLabel,"color":"cyan darken-1","type":"email","dir":"ltr","hint":_vm.contactNameByEmail(_vm.email),"persistent-hint":"","hide-details":!_vm.contactNameByEmail(_vm.email)},on:{"blur":_vm.onEmailInputBlur},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[(_vm.contacts.length)?_c('template',{slot:"append"},[_c(VIcon,{on:{"click":function($event){_vm.isAutoComplete = true}}},[_vm._v("mdi-notebook-outline")])],1):_vm._e()],2):_c(VAutocomplete,{style:([!_vm.isFullWidth && { display: 'inline-block', width: '250px', maxWidth: 'calc(100% - 34px)' }]),attrs:{"color":"cyan darken-1","items":_vm.contacts,"type":"email","filter":_vm.advancedContactSearch,"autofocus":"","dir":_vm.inputTextDirection,"no-data-text":_vm.$t('no_contacts_found'),"hide-details":!_vm.contactNameByEmail(_vm.email),"hint":_vm.contactNameByEmail(_vm.email),"persistent-hint":""},on:{"blur":_vm.onEmailInputBlur},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v("\n      "+_vm._s(data.item.value)+"\n    ")]}},{key:"item",fn:function(data){return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.text)}}),(data.item.category)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(data.item.category)}}):_vm._e()],1)]}}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }