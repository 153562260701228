import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"360"},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"patient-file-send-email-dialog",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,[_vm._v("\n      "+_vm._s(_vm.$t('share_email_title'))+"\n      "),_c(VSpacer),_c(VBtn,{class:_vm.isRtl ? 'float-left' : 'float-right',attrs:{"icon":"","color":"lighten-4"},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('EmailContactSearch',{attrs:{"email":_vm.emailTo,"inputLabel":_vm.$t('email_label'),"displayType":"shareFile","isFullWidth":true},on:{"updateEmail":_vm.onContactSelected}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"cyan darken-1","text":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.onShareClicked()}}},[_vm._v("\n        "+_vm._s(_vm.$t('share_email_btn'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }