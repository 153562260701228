import { render, staticRenderFns } from "./LoginPage.vue?vue&type=template&id=22feea14&"
import script from "./LoginPage.vue?vue&type=script&lang=ts&"
export * from "./LoginPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/login-page.locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fauth%2Fpages%2FLoginPage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports