import { AppHttpClient } from "@/core/http-client/http-client";
import { AxiosInstance } from "axios";
import { PatientNoteApiRouteParameters, PatientNoteApiRoutes } from "@/modules/patient/constants/api-routes";
import { Note } from "@/modules/patient/types/note.type";
import { PatientNoteCreateOrUpdateResponse } from "@/modules/patient/types/api-response.type";

interface NoteFormPayload {
  text: string;
  isPinned: boolean;

}
class PatientNoteApi {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = AppHttpClient.instance;
  }

  delete(noteId: number) {
    return this.httpClient.delete(
      PatientNoteApiRoutes.delete.replace(
        PatientNoteApiRouteParameters.id,
        noteId.toString()
      )
    );
  }

  async update(noteId: number, payload: {
    text: string;
    pinned: boolean;
  }): Promise<Note> {
    try {
      const response: PatientNoteCreateOrUpdateResponse = await this.httpClient.put(
        PatientNoteApiRoutes.update.replace(
          PatientNoteApiRouteParameters.id,
          noteId.toString()
        ),
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async create(payload: {
    patient_id: number;
    text: string;
    pinned: boolean;
  }) {
    try {
      const response: PatientNoteCreateOrUpdateResponse = await this.httpClient.post(
        PatientNoteApiRoutes.create,
        payload
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new PatientNoteApi();
