export const SETTINGS_PAGE_ROUTE_NAME= "settings";
export const SETTINGS_PAGE_ROUTE_PATH= "settings";

export const DRUGS_PAGE_ROUTE_NAME = "drugs-settings";
export const DRUGS_PAGE_ROUTE_PATH = "settings/drugs";

export const TAGS_PAGE_ROUTE_NAME = "tags-settings";
export const TAGS_PAGE_ROUTE_PATH = "settings/tags";

export const TREATMENTS_PAGE_ROUTE_NAME = "treatments-settings";
export const TREATMENTS_PAGE_ROUTE_PATH = "settings/treatments";

export const PRICE_LISTS_PAGE_ROUTE_NAME = "price-lists-settings";
export const PRICE_LISTS_PAGE_ROUTE_PATH = "settings/price-lists";

export const PATIENT_STATUSES_PAGE_ROUTE_NAME = "patient-statuses";
export const PATIENT_STATUSES_PAGE_ROUTE_PATH = "settings/patient-statuses";

export const TIME_ENTRY_TYPES_PAGE_ROUTE_NAME = "time-entry";
export const TIME_ENTRY_TYPES_PAGE_ROUTE_PATH = "settings/time-entry";

export const SHIFTS_PAGE_ROUTE_NAME = "shifts";
export const SHIFTS_PAGE_ROUTE_PATH = "settings/shifts";

export const CONTACT_BOOKMARKS_PAGE_ROUTE_NAME = "contact-bookmarks";
export const CONTACT_BOOKMARKS_PAGE_ROUTE_PATH = "settings/contact-bookmarks";

export const QUICK_ACTION_AND_TEMPLATE_LETTER_PAGE_ROUTE_NAME = "quick-action-and-template-letter";
export const QUICK_ACTION_AND_TEMPLATE_LETTER_PAGE_ROUTE_PATH = "settings/quick-action-and-template-letter";

export const QUICK_ACTION_PAGE_ROUTE_NAME = "quick-action-catagories";
export const QUICK_ACTION_PAGE_ROUTE_PATH = "catagories";

export const LETTER_PAGE_ROUTE_NAME = "template-letters";
export const LETTER_PAGE_ROUTE_PATH = "letters/:categoryId?";

export const SENTENCES_AND_CATAGORIES_PAGE_ROUTE_NAME = "sentences-and-catagories";
export const SENTENCES_AND_CATAGORIES_PAGE_ROUTE_PATH = "settings/sentences-and-catagories";

export const SENTENCES_CATAGORIES_PAGE_ROUTE_NAME = "sentences-catagories";
export const SENTENCES_CATAGORIES_PAGE_ROUTE_PATH = "categories";

export const SENTENCES_TEMPLATES_PAGE_ROUTE_NAME = "sentences-templates";
export const SENTENCES_TEMPLATES_PAGE_ROUTE_PATH = "sentences/:categoryId?";

export const APPOINTMENT_TYPE_PAGE_ROUTE_NAME = "appointment-type";
export const APPOINTMENT_TYPE_PAGE_ROUTE_PATH = "settings/appointment-type";

export const APPOINTMENT_STATUS_PAGE_ROUTE_NAME = "appointment-status";
export const APPOINTMENT_STATUS_PAGE_ROUTE_PATH = "settings/appointment-status";

export const IMAGINING_AGENT_PAGE_ROUTE_NAME = "imaging-agent";
export const IMAGINING_AGENT_PAGE_ROUTE_PATH = "settings/imaging-agent";

export const SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_NAME = "supplier-payment-categories";
export const SUPPLIER_PAYMENT_CATAGORIES_PAGE_ROUTE_PATH = "settings/supplier-payment-categories";