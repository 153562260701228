

















































































































import axios from "axios";
import { Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { REPORTS_ROUTE_NAME } from "../constants/route-config";
import PatientTreatmentApi from "@/modules/patient/api/patient-treatment.api";
import { PatientTreatment, TreatmentGroupByTreatmentName } from "@/modules/patient/types/patient-treatment.type";
import { date, time, age } from "@/filters/date.filter";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { PATIENT_ROUTE_NAME } from "@/modules/patient/constants/route-config";
import PatientTreatmentFormDialog from "@/modules/patient/components/PatientTreatmentFormDialog.vue";
import { Treatment } from "@/modules/treatment/types/treatment.type";
import moment from "moment";
import { enableDatePickerAdjacentMonths } from "@/modules/appointment/helpers/date-picker-helpers";
import DetailedTreatmentsReport from "../components/DetailedTreatmentsReport.vue";
import TreatmentSummaryReport from "../components/TreatmentSummaryReport.vue";

@Component({
  components: {
    PatientTreatmentFormDialog,
    DetailedTreatmentsReport,
    TreatmentSummaryReport

  },
  filters: {
    date,
    time,
    age,
  }
})
export default class ReportTreatmentsMonthlyPage extends mixins(SmileMixin) {
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isShowingTreatmentCode") isShowingTreatmentCode: boolean;

  private activeRequest;
  private treatmentsGroupByActiveRequest;
  public treatmentsGroupByTreatmentName: TreatmentGroupByTreatmentName[] = [];
  public patientTreatments: PatientTreatment[] = [];
  public displayUserIds: number[] = [];
  public treatmentIds: number[] = [];
  public excludeTreatmentIds: number[] = [];
  public fromDateFilter = moment().startOf("month").format("YYYY-MM-DD");
  public toDateFilter = moment().format("YYYY-MM-DD");
  public fromDateMenuOpen = false;
  public toDateMenuOpen = false;
  public isLoading = false;
  public isExportLoading = false;
  public totalItemsCount = 0;
  public itemsPerPage = 100;
  public dataTableOptions: any = {};
  public totalTreatmentsPrice = 0;
  public page = 1;
  public tab = 0;
  public totalTreatmentsCount = 0;

  get totalPrice() {
    if (this.patientTreatments.length === 0 && this.tab === 0) {
      return 0;
    } else if (this.treatmentsGroupByTreatmentName.length === 0 && this.tab === 1) {
      return 0;
    }
    return this.totalTreatmentsPrice
      .toLocaleString();
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get countTreatments() {
    if (this.patientTreatments.length === 0 && this.tab === 0) {
      return 0;
    } else if (this.treatmentsGroupByTreatmentName.length === 0 && this.tab === 1) {
      return 0;
    }

    return this.totalTreatmentsCount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  get therapistUsers() {
    const documentedUserIds = new Set(this.patientTreatments.map(({ user_id }) => (user_id)));
    const therapistIds = this.$store.getters["auth/therapistUsers"].map(({ user_id }) => (user_id));
    const extraNonTherapistUsers = this.$store.getters["auth/users"].filter(({ id }) => ((documentedUserIds.has(id) && therapistIds.indexOf(id) === -1) || this.displayUserIds.indexOf(id) !== -1));

    return [
      ...this.$store.getters["auth/therapistUsers"],
      ...extraNonTherapistUsers,
    ].map(user => ({
      "text": user.name,
      "value": user.id
    }));
  }
  public setTab(tabValue: number) {
    this.tab = tabValue;
    this.setPage(1);
  }
  public updated() {
    this.enableAdjacentMonths();
  }

  public treatmentsFilter(item: any, queryText: string) {
    return `${item.text} ${item.code}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }

  public enableAdjacentMonths() {
    setTimeout(() => enableDatePickerAdjacentMonths(), 50);
  }

  get reportsPageRouteName() {
    return REPORTS_ROUTE_NAME;
  }


  public applyFromQueryStringFilters() {
    const queryFrom = this.$route.query.from && this.$route.query.from.toString();
    const queryTo = this.$route.query.to && this.$route.query.to.toString();
    const queryCalendar = this.$route.query.calendar && this.$route.query.calendar.toString();
    const queryTreatments = this.$route.query.treatment && this.$route.query.treatment.toString();
    const queryExcludeTreatments = this.$route.query.exclude_treatment && this.$route.query.exclude_treatment.toString();
    const queryPage = this.$route.query.page && this.$route.query.page.toString();

    if (queryFrom) {
      this.fromDateFilter = queryFrom;
    }
    if (queryTo) {
      this.toDateFilter = queryTo;
    }
    if (parseInt(queryPage) > 1) {
      this.setPage(parseInt(queryPage));

    }
    if (queryCalendar) {
      this.displayUserIds = queryCalendar.split(",").map(id => (parseInt(id)));
    }
    if (queryTreatments) {
      this.treatmentIds = queryTreatments.split(",").map(id => (parseInt(id)));
    }
    if (queryExcludeTreatments) {
      this.excludeTreatmentIds = queryExcludeTreatments.split(",").map(id => (parseInt(id)));
    }
  }
  public displayTreatmentFormDialog = false;
  public editingTreatment: PatientTreatment = null;

  public onEditTreatmentClicked(treatment: PatientTreatment) {
    this.displayTreatmentFormDialog = true;
    this.editingTreatment = treatment;
  }

  public setPage(page: number) {
    this.page = page;
    this.dataTableOptions.page = page;
  }

  @Watch("excludeTreatmentIds")
  @Watch("treatmentIds")
  @Watch("displayUserIds")
  @Watch("fromDateFilter")
  @Watch("toDateFilter")
  @Watch("tab")
  @Watch("page")
  onFiltersChanged() {
    this.reload();
  }

  get treatmentOptions() {
    const paymentTreatment = this.$store.getters["auth/treatments"]
      .find((treatment: Treatment) => (treatment.name === "תשלום" && !treatment.deleted_at));

    return (this.$store.getters["auth/treatments"] as Treatment[])
      .filter(treatment => !treatment.deleted_at)
      .filter(treatment => this.$store.getters["auth/isShowingPaymentTreatments"] || !paymentTreatment || (!this.$store.getters["auth/isShowingPaymentTreatments"] && treatment.id !== paymentTreatment.id))
      .map(treatment => ({
        "text": treatment.name,
        "code": treatment.code,
        "value": treatment.id
      }));
  }

  public async reload() {
    const { page } = this.dataTableOptions;
    this.page = page;

    this.updateQueryStringFilters();
    this.isLoading = true;
    this.patientTreatments = [];

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    if (this.treatmentsGroupByActiveRequest) {
      this.treatmentsGroupByActiveRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();
    this.treatmentsGroupByActiveRequest = axios.CancelToken.source();

    try {
      const response = await PatientTreatmentApi
        .find(this.itemsPerPage, this.page, this.displayUserIds, this.treatmentIds, this.excludeTreatmentIds, this.fromDateFilter, this.toDateFilter, this.activeRequest);
      this.totalTreatmentsPrice = parseFloat(parseFloat(response.totalPrice.toString()).toFixed(1));
      this.totalTreatmentsCount = response.paginate.total;
      if (this.tab === 0) {
        this.patientTreatments = response.paginate.data;
        this.totalItemsCount = response.paginate.total;
      } else {
        const treatmentsGroupByTreatmentNameResult = await PatientTreatmentApi
          .getTreatmentGroupByTreatmentName(this.itemsPerPage, this.page, this.displayUserIds, this.treatmentIds, this.excludeTreatmentIds, this.fromDateFilter, this.toDateFilter, this.treatmentsGroupByActiveRequest);
        this.treatmentsGroupByTreatmentName = treatmentsGroupByTreatmentNameResult.paginate.data;
        this.totalItemsCount = treatmentsGroupByTreatmentNameResult.paginate.total;
      }
      this.isLoading = false;
    } catch (err) {

      if (axios.isCancel(err)) {
        return;
      }

      this.isLoading = false;
      this.$toastr.e(this.$t("temporary_error"));
    }
  }

  public filterTherapistUserByDefault() {
    if (this.displayUserIds.length) {
      return;
    }

    if (!this.$store.getters["auth/isTherapist"]) {
      return;
    }

    this.displayUserIds = [this.$store.getters["auth/userId"]];
  }

  public updateQueryStringFilters() {
    const query = {
      page: this.page > 1 ? this.page.toString() : undefined,
      from: this.fromDateFilter,
      to: this.toDateFilter,
      calendar: this.displayUserIds.length ? this.displayUserIds.join(",") : undefined,
      treatment: this.treatmentIds.length ? this.treatmentIds.join(",") : undefined,
      exclude_treatment: this.excludeTreatmentIds.length ? this.excludeTreatmentIds.join(",") : undefined,

    };

    this.$router.replace({ ...this.$router.currentRoute, query });
  }

  public mounted() {
    this.applyFromQueryStringFilters();
    this.filterTherapistUserByDefault();
    this.reload();
  }

  get formattedFromDateFilter() {
    if (!this.fromDateFilter) {
      return "";
    }
    return moment(this.fromDateFilter).format("DD/MM/YYYY");
  }

  get formattedToDateFilter() {
    if (!this.toDateFilter) {
      return "";
    }
    return moment(this.toDateFilter).format("DD/MM/YYYY");
  }

  get patientRouteName() {
    return PATIENT_ROUTE_NAME;
  }
  public async onExportClicked() {
    this.isExportLoading = true;

    try {
      const url = await PatientTreatmentApi.getExportUrl(this.displayUserIds, this.treatmentIds, this.excludeTreatmentIds, this.fromDateFilter, this.toDateFilter);
      this.isExportLoading = false;

      const res = await this.$confirm(
        this.$t("export_confirm_message").toString(),
        {
          buttonTrueColor: "cyan darken-1",
          buttonTrueText: this.$t("export_ok_btn").toString(),
          buttonFalseText: "",
        });

      if (!res) {
        return;
      }

      this.downloadFile(url, this.$t("reports.monthly-treatments-report.title") + ".xlsx");
    } catch (err) {
      this.$toastr.e(this.$t("temporary_error"));
      this.isExportLoading = false;
    }
  }

}
